import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_ASK_MESSAGE,
  SET_CHANGE_THEME,
  SET_CHATS_DATA,
  SET_PROCEDURE,
  SET_IS_LOADING,
} from "../../../constants/esgGptConstant";

const initial_state = {
  acknowledged: false,
  organizations: [],
  newOrganization: {
    _id: 4,
    persona: 5,
  },
  newLogo: "",
  askMessage: "",
  hasDefaultTheme: true,
  allChats: [],
  procedure: "",
  isLoading: false,
};

export default function esgGptReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ACKNOWLEDGED:
      return (state = { ...state, acknowledged: action.payload });
    case SET_ORGANIZATION:
      return (state = { ...state, organizations: action.payload });
    case SET_NEW_ORGANIZATION:
      return (state = { ...state, newOrganization: action.payload });
    case SET_NEW_ORGANIZATION_LOGO:
      return (state = { ...state, newLogo: action.payload });
    case SET_ASK_MESSAGE:
      return (state = { ...state, askMessage: action.payload });
    case SET_CHANGE_THEME:
      return (state = { ...state, hasDefaultTheme: action.payload });
    case SET_PROCEDURE:
      return (state = { ...state, procedure: action.payload });
    case SET_IS_LOADING:
      return (state = { ...state, isLoading: action.payload });
    case SET_CHATS_DATA:
      const filterChats = state.allChats.filter((c) => !c.isLoading);

      filterChats.push({
        isLoading: false,
        ...action.payload,
      });
      return (state = { ...state, allChats: [...filterChats] });
    default:
      return state;
  }
}
