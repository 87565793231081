import React from "react";
import { connect } from "react-redux";
import RiskDashboard from "../../components/widgets/riskDashboard/riskDashboard";
import { getCompanyData, getElasticOrganizations } from "../../redux/actions/riskdashboard/riskDashboardActions";

function RiskDashboardCont(props) {
  return <RiskDashboard {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  riskDashboard:store.riskDashboard
});

export const mapDispatchToProps = (dispatch) => ({
    getElasticOrganizations: (token ,limit,starting_after) => {
    dispatch(getElasticOrganizations(token ,limit,starting_after));
  },
  getCompanyData: (token, company_id) => {
    dispatch(getCompanyData(token, company_id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RiskDashboardCont);
