import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Button,
  Grid,
  Icon,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Slide,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import analytics from "../../../images/roboAi.svg";
import promptIconWhite from "../../../images/promptIcon.png";
import promptIcon from "../../../images/gieom/Prompt Icon.png";
import useHoverEffect from "../../../customHooks/getHoveringEffects";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatData,
  setProcedure,
} from "../../../redux/actions/gpt/esgGptChatsAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // border: "1px solid #EAEEF5",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.light,

    borderRadius: "8px",
    // backgroundColor: isHovering ? "#EBF1F8" : "#FFFFFF",
    padding: "13px",
    justifyContent: "flex-start",
    width: "49%",
    // marginBottom: "10px",
    cursor: "pointer",
    alignItems: "center",

    backgroundColor: theme.palette.grey[600],

    "&:hover": {
      backgroundColor: "#294E95",
      color: theme.palette.primary.main,
      "& $typography": {
        color: theme.palette.primary.main,
      },
    },
    menuItem: {
      "&:hover": {
        color: "red",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "80%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "49%",
      padding: "10px",
      alignItems: "center",

      "@media (orientation: portrait)": {
        width: "90%",
      },
    },
    // [theme.breakpoints.up("xs")]: {
    //   width: "49%",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  mainGpt: {
    height: "80vh",
    // flexWrap: "wrap",
    display: "flex",

    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "solid",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      "@media (orientation: portrait)": {
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "65vh",
      flexDirection: "column",
      // padding: "0px 20px",
    },
    // [theme.breakpoints.down("sm")]: {
    [theme.breakpoints.down("xs")]: {
      height: "98%",

      flexDirection: "column",
      padding: "0px 20px",
      // width:'100%'
    },
    // [theme.breakpoints.down("sm")]: {
    //   height: "65vh",
    //   flexDirection:'column'
    // },
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.blue,
    fontSize: "13px",
    // "&:hover": {
    //   color: theme.palette.primary.main,
    // },
  },
  typography2: {
    ...theme.typography.body2,
    color: theme.palette.primary.dark,
    padding: "15px 0px",
  },
  typograph3: {
    ...theme.typography.body1,
    fontSize: "24px",
    paddingBottom: 15,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
    },
  },
  iconButton: {
    marginLeft: "auto",
    borderRadius: "200px",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "5px",
    width: "30px",
    borderColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontWeight: 400,
  },
  roboAi: {
    border: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: "30px",
    height: "100%",
    alignItems: "center",
    borderRadius: "675px",
    // [theme.breakpoints.down("md")]: {
    //   "@media (orientation: portrait)": {
    //     height: "80%",
    //   },
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "80%",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: "60%",
    // },
  },
  imgContainer: {
    height: "80%",
    marginTop: "5px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: portrait)": {
        height: "45%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "30%",
    },
  },
}));

function EsgGptMainTitle({ title, newPath, login, procedure }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const handleChangeRoute = () => {
    let path = newPath;
    history.push(path);
    dispatch(getChatData(login.token, title, procedure));
  };

  return (
    <Grid
      item
      className={classes.root}
      onClick={handleChangeRoute}
      onMouseEnter={() => handleMouseEnter(true)}
      onMouseLeave={() => handleMouseLeave(true)}
    >
      <img
        style={{
          width: "30px",
          height: "24px",
          paddingRight: 8,
        }}
        src={promptIcon}
        alt=""
      />
      <Typography className={classes.typography}>{title}</Typography>
      {/* <IconButton
        variant="contained"
        className={classes.iconButton}
        aria-label="add to shopping cart"
      >
        <ArrowForwardIosIcon
          className={classes.icon}
          style={{
            color: theme.palette.primary.contrastColor,
          }}
          // style={{  fontSize: "20px" }}
          // fontSize="medium"
        />
      </IconButton> */}
    </Grid>
  );
}

function EsgGptMainRight() {
  const classes = useStyles();
  const { login, esgGptReducer } = useSelector((state) => state);
  const [isProcedure, setIsProcedure] = useState(!esgGptReducer.procedure);
  const magPieProcedures = [
    "Gold and Private banking",
  ];
  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      "&:hover": {
        "&& fieldset": {
          border: "3px solid green",
        },
      },
    },
  }))(InputBase);
  const Procedure = ({ procedure, isProcedure, setIsProcedure }) => {
    const dispatch = useDispatch();
    return (
      <Slide direction="down" in={isProcedure} mountOnEnter unmountOnExit>
        <Grid
          item
          md={8}
          sm={8}
          style={{
            // height: "50%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "flex-start",
            // width: "50%",
            alignItems: "center",
            // border: "solid",
            // flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <Typography
              className={classes.typograph3}
              // style={{
              //   fontSize: "14px",
              //   fontWeight: 600,
              //   letterSpacing: "0.014px",
              //   textTransform: "capitalize",
              //   //   cursor:"pointer"
              // }}
            >
              MAGPIE
              {/* Lenovo's Intelligent Sustainability Solutions Designer */}
            </Typography>
            <Typography
              className={classes.typography2}
              // style={{
              //   fontSize: "14px",
              //   fontWeight: 600,
              //   letterSpacing: "0.014px",
              //   textTransform: "capitalize",
              //   //   cursor:"pointer"
              // }}
            >
              Please Select a Procedure.
            </Typography>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
            }}
          >
            <Select
            // className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                "&:hover": {
                  borderRadius: 4,
                  backgroundColor: "#80bdff",
                  color: "red",
                  // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                },
                PaperProps: {},
              }}
              size="small"
              fullWidth
              input={<BootstrapInput />}
              onChange={(e) => {
                dispatch(setProcedure(e.target.value));//e.target.value));
                setIsProcedure(false);
              }}
              renderValue={
                procedure !== "" ? undefined : () => "Select Procedure"
              }
              value={procedure || 'Select Procedure'}
            >
              {magPieProcedures.map((proc) => (
                <MenuItem className={classes.menuItem}  value={proc}>
                  {proc}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
      </Slide>
    );
  };
  
  return (
    <Grid
      container
      // component={Paper}
      // elevation={0}
      // justifyContent="center"
      // direction="column"
      // alignItems="center"
      md={12}
      className={classes.mainGpt}
    >
      <Grid
        md={10}
        style={{
          display: "flex",
          alignItems: "center",
          height: "50vh",
          borderRadius: 10,
          border: "1px solid #F1E1ED",
          justifyContent: "space-around",
        }}
      >
        {/* <Slide direction="down" in={isProcedure} mountOnEnter unmountOnExit> */}
        <Grid item md={3} sm={3} className={classes.imgContainer}>
          <img src={analytics} alt="analytics" className={classes.roboAi} />
        </Grid>
        {/* </Slide> */}

        {isProcedure ? (
          <Procedure
            procedure={esgGptReducer.procedure}
            setIsProcedure={setIsProcedure}
            isProcedure={isProcedure}
          />
        ) : (
          <Slide direction="down" in={!isProcedure} mountOnEnter unmountOnExit>
            <Grid
              item
              md={8}
              sm={8}
              style={{
                // height: "50%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "flex-start",
                // width: "50%",
                alignItems: "center",
                // border: "solid",
                // flexWrap: "wrap",
                // opacity: 1,
                // transition: "opacity 1000ms ease-in",
              }}
            >
              <div style={{ width: "100%" }}>
                <Typography
                  className={classes.typograph3}
                  // style={{
                  //   fontSize: "14px",
                  //   fontWeight: 600,
                  //   letterSpacing: "0.014px",
                  //   textTransform: "capitalize",
                  //   //   cursor:"pointer"
                  // }}
                >
                  {/* Procedure : System Data Entry & Authorization */}
                  {/* Lenovo's Intelligent Sustainability Solutions Designer  */}
                </Typography>
                <Typography className={classes.typography2}>
                  Please add in your prompt
                </Typography>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 10,
                  flexWrap: "wrap",
                }}
              >
                <EsgGptMainTitle
                  title={"Operational Risks"}
                  newPath={"/esg_gpt/chat"}
                  login={login}
                  procedure={esgGptReducer.procedure}
                />
                <EsgGptMainTitle
                  title={"Basel II Categories of Operational Risks"}
                  newPath={"/esg_gpt/chat"}
                  login={login}
                  procedure={esgGptReducer.procedure}
                />
                <EsgGptMainTitle
                  title={"Customer Touchpoints"}
                  newPath={"/esg_gpt/chat"}
                  login={login}
                  procedure={esgGptReducer.procedure}
                />
              </div>
            </Grid>
          </Slide>
        )}
      </Grid>
    </Grid>
  );
}

export default EsgGptMainRight;
