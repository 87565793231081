import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NavCustomDrawer from "../UI/drawer/drawer";
import CustomAppBar from "../UI/appBar/appBar";
import Login from "../../containers/resetPasswordCont/resetPasswordCont";
// import LoginCon from "../../containers/login/login-cont";
// import SurveyListingCont from "../../containers/surveyListing/surveyListingCont";
// import ReportListingCont from "../../containers/reportListing/reportListingCont";
// import ESGReportListingCont from "../../containers/esrReportListing/esgReportListingCont";
// import SingleESGReportCont from "../../containers/singleESGReport/sigleESGReportCont";
// import MetricReport from "../screen/singleESGReport/metricReport";
// import SustainabilityReportCont from "../../containers/SustainabilityReportMain/SustainabilityReportMainCont";
import "../router/router.css";
import MobileDrawer from "../UI/menuBar/menuBar";
// import OnboaringCon from "../../containers/onboarding/onboarding-cont";
// import signupContainer from "../../containers/signup/signupContainer";
import Controller_con from "../../containers/router/controllerCont";
import LoaderCon from "../../containers/loader/loader_cont";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
// import EsgReport from "../widgets/esgReport";
// import Drawer from "../widgets/policies/drawer";
// import FAQ from "../widgets/policies/faq";
// import GeneralSettingsCont from "../../containers/generalSettings/generalSettingsCont";
// import ResetPasswordCon from "./../../containers/resetPasswordCont/resetPasswordCont";
// import OnboardinTour from "../screen/onboardinTour/onboardinTour";
// import BasicDetailsCont from "../../containers/basicDetails/basicDetailsCont";
// import GetStarted from "../screen/getStarted/getStarted";
// import SurveyUp from "../UI/maturityMatrix/SurveyUp";
// import Survey from "../UI/maturityMatrix/Survey";
// import GetStartedCont from "../../containers/getStarted/getStartedCont";
// import TnC from "../widgets/policies/T&C";
// import Privacy from "../widgets/policies/privacy";
// import DataHubCont from "../../containers/dataHub/dataHubCont";
// import DataHubSurveyCont from "../../containers/dataHub/dataHubSurveyCont";
// import AppDashBoard from "../UI/dataHubCalculatedCharts/AppDashBoard";
// import { DashBoardScreen } from "../screen/dashboard/dashboard";
// import DashboardCont from "../../containers/dashboard/dashboardCont";
import riskAssessmentCont from "../../containers/riskAssessment/riskAssessmentCont";
import CorporateCont from "../../containers/riskAssessment/corporateCont";
import CorporateEvaluatorCont from "../../containers/riskAssessment/corporateEvaluatorCont";
import BrmDetailsCont from "../../containers/riskAssessment/brmDetailsCont";
import LoginCont from "../../containers/login/LoginCont";
import CorporateSurveyTaskCont from "../../containers/corporateSurveyTaskCont/corporateSurveyTaskCont";
import ResetPasswordCon from "../../containers/resetPasswordCont/resetPasswordCont";
import RiskDashboardCont from "../../containers/riskDashboardCont/riskDashboardCont";
import RiskDocument from "../widgets/riskDashboard/riskDocument";
import riskDocumentCont from "../../containers/riskDashboardCont/riskDocumentCont";
import EsgGptMain from "../screen/esgGpt/EsgGptMain";
import EsgGptCompanyProfiles from "../widgets/esgGpt/esgGptCompanyProfile/esgGptCompanyProfiles";
import EsgGptAddNewCompany from "../widgets/esgGpt/esgGptAddNewCompany/EsgGptAddNewCompany";
import EsgGptChat from "../widgets/esgGpt/esgGptChat/EsgGptChat";
import { Box, Container, Paper } from "@material-ui/core";
export const MContext = React.createContext();

class Router extends React.Component {
  autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = this.props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Redirect to="/login" />;
  };

  state = {
    isOpen: false,
    updatePath: false,
    addNewCompany: false,
  };

  cb = (setIsOpen) => {
    this.setState({ isOpen: setIsOpen });
  };

  // componentDidUpdate() {
  //   if (
  //     false &&
  //     this.props.login.isLoggedIn === true &&
  //     window.location.pathname === "/add_company" &&
  //     !this.state.addNewCompany
  //   ) {
  //     this.setState({
  //       addNewCompany: true,
  //     });
  //   }
  // }

  render() {
    const { login, logOut } = this.props;
    //
    // const userType = "gpt";
    const userType = localStorage.getItem("user_type");
    // if (snackbar.response_received && snackbar.message === "Invalid user") {
    // this.autoMateLogout();
    // }

    let body;
    // if (true) {
    if (login.isLoggedIn === false && !localStorage.getItem("user_token")) {
      body = (
        <div>
          <Route path="/" component={Controller_con} />
          <Route path="/login" component={LoginCont} />
          <Route exact path="/reset_password" component={ResetPasswordCon} />
        </div>
      );
    } else if (
      login.isLoggedIn === true ||
      localStorage.getItem("user_token")
    ) {
      body = (
        <Switch>
          <Container
            elevation={0}
            disableGutters
            maxWidth={false}
            style={{ borderRadius: "0px" }}
            className="div-post-login"
          >
            {userType !== "gpt" && (
              <NavCustomDrawer
                isOpen={this.isOpen}
                setIsOpen={this.cb}
                logOut={logOut}
                token={login.token}
                user_type={login.user_type}
                login={login}
                // redirect={surveyListing.redirect_value}
                setRedirectValue={this.props.setRedirectValue}
              />
            )}

            {userType !== "gpt" && (
              <CustomAppBar
                logOut={logOut}
                isOpen={this.state.isOpen}
                lenevoNav={userType}
                {...this.props}
              />
            )}
            {userType === "gpt" && (
              <MobileDrawer login={login} user_type={login.user_type} token={login.token} logOut={logOut} />
            )}
            {/* <NavCustomDrawer
              isOpen={this.isOpen}
              setIsOpen={this.cb}
              logOut={logOut}
              token={login.token}
              user_type={login.user_type}
              login={login}
              // redirect={surveyListing.redirect_value}
              setRedirectValue={this.props.setRedirectValue}
            />
            <CustomAppBar
              logOut={logOut}
              isOpen={this.state.isOpen}
              {...this.props}
            />
            <MobileDrawer /> */}
            <Route path="/" component={Controller_con} />

            <Route exact path="/risk_analysis" component={riskAssessmentCont} />
            <Route exact path="/risk_admin" component={RiskDashboardCont} />
            <Route exact path="/risk_admin/doc" component={riskDocumentCont} />
            <Route
              exact
              path="/risk_analysis/company_details"
              render={() => (
                <BrmDetailsCont isEvaluator={false} {...this.props} />
              )}
            />
            <Route
              exact
              path="/risk_evaluator/company_details"
              render={() => (
                <BrmDetailsCont isEvaluator={true} {...this.props} />
              )}
            />
            <Route
              exact
              path="/corporate_questions"
              component={CorporateCont}
            />
            <Route
              exact
              path="/corporate_questions/survey"
              render={() => (
                <CorporateSurveyTaskCont isEvaluator={true} {...this.props} />
              )}
            />
            <Route
              exact
              path="/risk_evaluator"
              component={CorporateEvaluatorCont}
            />
            <Route exact path="/esg_gpt" component={EsgGptMain} />
            <Route
              exact
              path="/esg_gpt/company_profiles"
              component={EsgGptCompanyProfiles}
            />
            <Route
              exact
              path="/esg_gpt/add_company"
              component={EsgGptAddNewCompany}
            />
            <Route exact path="/esg_gpt/chat" component={EsgGptChat} />
            {userType === "RM" && (
              <Route render={() => <Redirect to="/risk_analysis" />} />
            )}
            {userType === "RE" && (
              <Route render={() => <Redirect to="/risk_evaluator" />} />
            )}
            {userType === "corp" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}
            {userType === "RA" && (
              <Route render={() => <Redirect to="/risk_admin" />} />
            )}
            {userType === "gpt" && (
              <Route render={() => <Redirect to="/esg_gpt" />} />
            )}
            {/* {window.location.pathname === "/privacy_policy" && (
              <Route render={() => <Redirect to="/privacy_policy" />} />
            )}
            {window.location.pathname === "/tnc" && (
              <Route render={() => <Redirect to="/tnc" />} />
            )}
            {window.location.pathname === "/faq" && (
              <Route render={() => <Redirect to="/faq" />} />
            )} */}
            {/* <Route exact path="/drawer" component={Drawer} /> */}
            {/* <Route exact path="/tnc" component={FAQ} /> */}
            {window.location.pathname === "/risk_analysis" && (
              <Route render={() => <Redirect to="/risk_analysis" />} />
            )}
            {window.location.pathname === "/risk_evaluator" && (
              <Route render={() => <Redirect to="/risk_evaluator" />} />
            )}
            {window.location.pathname === "/corporate_questions" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}
          </Container>
        </Switch>
      );
    }

    return (
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon {...this.props} />
        {body}
      </BrowserRouter>
    );
  }
}

export default Router;
