import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import {
  RESET_CORPORATE,
  SET_CORPORATE_ANSWERS,
  SET_CORPORATE_QUESTIONS,
  SET_SURVEY_QUESTIONS,
} from "../../../constants/corporateSurveyConstants";
import { set_loader, unset_loader } from "../loader/loader_action";

export const setCorporateQuestions = (payload) => {
  return {
    type: SET_CORPORATE_QUESTIONS,
    payload,
  };
};

export const setSurveyQuestions = (payload) => {
  return {
    type: SET_SURVEY_QUESTIONS,
    payload,
  };
};

export const setCorporateAnswers = (payload) => {
  return {
    type: SET_CORPORATE_ANSWERS,
    payload,
  };
};

export const getCorporateQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCorporateQuestions(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

export const getSurveyeQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_all_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSurveyQuestions(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};

export const addSurveyeQuestions = (token, organization_id, question) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
      question,
    });
    console.log(question);
    return fetch(UNIVERSAL.BASEURL + "/questions/add_question ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSurveyeQuestions(token, organization_id));
        }
        dispatch(unset_loader);
      });
  };
};

export const submitAnswer = (
  token,
  organization_id,
  question_id,
  value,
  file
) => {
  console.log("api", question_id, value);
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id,
      question_id,
      value,
    });
    var formData = new FormData();
    formData.append("data", data);
    formData.append("file", file);
    return fetch(UNIVERSAL.BASEURL + "/questions/submit_answer", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("submitAnswer response", responseJson);
        if (responseJson.status) {
          // Array.isArray(responseJson.result) && dispatch(setCorporateAnswers(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};
export const getAssignedQuestions = (token, organization_id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      organization_id: organization_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/questions/get_assigned_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response", responseJson);
        if (responseJson.status) {
          dispatch(setCorporateAnswers(responseJson.result));
        }
        dispatch(unset_loader);
      });
  };
};
