import React from "react";

import { InputAdornment, Paper, TextField, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Textfield from "../../UI/textfield/textfield";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD",
      borderRadius: "8px",
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD",
      borderRadius: "8px",
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
      borderRadius: "8px",
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
      marginTop: "5px",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: theme.palette.primary.main,
    },

    // ...theme.typography.body2,
    flexGrow: 1,
    // color: theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.light,
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    color: "red",
    borderRadius: "10px",
  },
  label: {
    ...theme.typography.body2,
    // color: "red",

    // border:"solid",
    // textAlign:"center"
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  notchedOutline: {},
}));

export default function EsgGptSearchBar() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    // <Paper
    //   style={{
    //     width: "90%",
    //     marginLeft: "10px",
    //     marginTop: "10px",
    //     borderRadius: "8px",
    //     border: "1px solid red",
    //   }}
    //   elevation={0}
    // >
    <TextField
      className={classes.root}
      id="outlined-basic"
      name="Search"
      variant="outlined"
      fullWidth
      size="medium"
      type="text"
      label="Search by Ticket / Name"
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      onChange={(e) => {
        //   setSearchCompanyText(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              className={classes.icon}
              style={{
                color: theme.palette.primary.contrastColor,
              }}
            />
          </InputAdornment>
        ),
      }}
      // value={searchCompanyText}
    />
    //  </Paper>
  );
}
