import { makeStyles } from '@material-ui/core';

const menuBarUseStyles = makeStyles((theme) => ({
  hideShow: {
    zIndex:1000,
    visibility: 'hidden',
    height: '0',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      height: 'auto',
    },
  },
  link: { textDecoration: 'none' },
  divider: { marginTop: 'auto' },
  mainGrid: { height: '70px' },
  icon: { color: '#0873d0' },
  img: { float: 'right', marginRight: '10px' },
}));

export default menuBarUseStyles;
