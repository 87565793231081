import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";
import { valueOrDefault } from "../../../../node_modules/chart.js/dist/helpers.mjs";

function StackedBarChart(props) {
  const NUMBER_CFG = { count: 7, min: 1, max: 20 };
  var _seed = Date.now();
  const rand = (min, max) => {
    min = valueOrDefault(min, 0);
    max = valueOrDefault(max, 0);
    _seed = (_seed * 9301 + 49297) % 233280;
    return min + (_seed / 233280) * (max - min);
  };
  function numbers(config) {
    var cfg = config || {};
    var min = valueOrDefault(cfg.min, 0);
    var max = valueOrDefault(cfg.max, 100);
    var from = valueOrDefault(cfg.from, []);
    var count = valueOrDefault(cfg.count, 8);
    var decimals = valueOrDefault(cfg.decimals, 8);
    var continuity = valueOrDefault(cfg.continuity, 1);
    var dfactor = Math.pow(10, decimals) || 0;
    var data = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = (from[i] || 0) + rand(min, max);
      if (rand() <= continuity) {
        data.push(Math.round(dfactor * value) / dfactor);
      } else {
        data.push(null);
      }
    }
    return data;
  }

  // const CHART_COLORS = {
  //   red: "rgb(255, 99, 132)",
  //   orange: "rgb(255, 159, 64)",
  //   yellow: "rgb(255, 205, 86)",
  //   green: "rgb(75, 192, 192)",
  //   blue: "rgb(54, 162, 235)",
  //   purple: "rgb(153, 102, 255)",
  //   grey: "rgb(201, 203, 207)",
  // };
  let dynamicColors = [
    "rgb(255, 99, 132)",
    "rgb(255, 159, 64)",
    "rgb(255, 205, 86)",
    "rgb(75, 192, 192)",
    "rgb(54, 162, 235)",
    "rgb(153, 102, 255)",
    "rgb(201, 203, 207)",
  ];
  // const labels = [
  //   ["Al ahli ", "bank of", "kuwait"],
  //   ["Gulf bank", "kscp"],
  //   ["Ahli united", "bank - kuwait"],
  //   ["kuwait", "international", "bank"],
  //   ["national", "bank of", "kuwait"],
  //   ["commercial", "bank of", "kuwait"],
  // ];

  const options = {
    maintainAspectRatio: false,
    //   aspectRatio: 1.99,
    scales: {
      // r: {
      //   suggestedMin: 0,
      //   suggestedMax: 1,
      // },
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      // tooltip: false,
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
      beforeDraw: (chart, steps, options) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = "white";
        ctx.restore();
      },
    },
    elements: {
      arc: {
        backgroundColor: "white",
        hoverBackgroundColor: "25px",
      },
    },
  };
  const labels = props?.riskEvaluator?.sentimentBarGraph
    ? props.riskEvaluator?.sentimentBarGraph?.labels.map((label) => {
        return label.split(" ");
      })
    : [];
  const dataSets =
    props?.riskEvaluator &&
    props?.riskEvaluator?.sentimentBarGraph &&
    Array.isArray(props?.riskEvaluator?.sentimentBarGraph?.datasets)
      ? props?.riskEvaluator?.sentimentBarGraph?.datasets.map((data, i) => {
          return {
            ...data,
            stack: "Stack 0",
            backgroundColor: dynamicColors[i],
          };
        })
      : [];
  const data = {
    labels: labels,
    datasets: dataSets,
    // datasets: [
    //   {
    //     label: "Community",
    //     data: [
    //       75.76832144987499, 71.22605343522697, 70.49116080891002,
    //       70.87843554618023, 58.53680019689129, 20, 34, 22, 34, 67,
    //     ],
    //     backgroundColor: CHART_COLORS.red,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Human Rights",
    //     data: numbers(NUMBER_CFG),
    //     backgroundColor: CHART_COLORS.blue,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Management",
    //     data: [
    //       77.21631200262841, 73.93678145573057, 71.65909018083052,
    //       70.24269663427295, 57.49115262365645, 20, 14, 45, 34, 22,
    //     ],
    //     backgroundColor: CHART_COLORS.green,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Product Responsiblity",
    //     data: [
    //       75.2777777056322, 70.6609193648415, 70.2960850686738,
    //       70.2720494925771, 59.337106877493675, 20, 14, 45, 34, 22,
    //     ],
    //     backgroundColor: CHART_COLORS.yellow,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Resource Use",
    //     data: [
    //       74.2375885983731, 70.20114918412834, 70.791665874828,
    //       71.37671729215641, 58.12455676619415, 20, 14, 45, 34, 22,
    //     ],
    //     backgroundColor: CHART_COLORS.orange,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Shareholders",
    //     data: [
    //       74.2375885983731, 70.20114918412834, 70.791665874828,
    //       71.37671729215641, 58.12455676619415, 20, 14, 45, 34, 22,
    //     ],
    //     backgroundColor: CHART_COLORS.purple,
    //     stack: "Stack 0",
    //   },
    //   {
    //     label: "Workforce",
    //     data: [
    //       75.88652476858586, 70.51724119844108, 69.9365522146225,
    //       69.76159699921756, 60.03980803439065, 25, 14, 45, 34, 22,
    //     ],
    //     backgroundColor: CHART_COLORS.grey,
    //     stack: "Stack 0",
    //   },
    // ],
  };
  const config = {
    type: "bar",
    data: data,
    options: {
      plugins: {
        title: {
          display: true,
          text: "Chart.js Bar Chart - Stacked",
        },
      },
      responsive: true,

      scales: {
        // x: {
        //   ticks: { autoSkip: false,
        //     maxRotation: 90,
        //     minRotation: 90,
        //   },
        // },
        x: {
          stacked: true,
        },
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          },
        ],
        // xAxes: [
        //   {
        //     ticks: {
        //       autoSkip: false,
        //       maxRotation: 30,
        //       callback: (value, index, values) => {
        //         let label = labels[value];
        //         if (label == null) {
        //           return "";
        //         }
        //         if (label.length > 15) {
        //           label = label.substring(0, 15);
        //           label += "..";
        //         }
        //         return label;
        //       },
        //     },
        //   },
        // ],
        // y: {
        //   stacked: true
        // }
      },
    },
  };
  return (
    <Grid style={{ marginTop: "16px", padding: "5px", height: "96%" }}>
    {/* // <Grid style={{ marginTop: "16px", padding: "5px", height: "100%" }}> */}
      <Bar config={config} options={options} data={data} />
    </Grid>
  );
}

export default StackedBarChart;
