import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

// const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.width,
    flexShrink: 0,
    // border:"solid red",
    // zIndex: 9,
  },
  drawerPaper: {
    width: (props) => props.width,
    display: "flex",
    justifyContent: "flex-start",
    border: `1px solid ${theme.palette.primary.light}`,
  },
  buttonDiv: {
    // borderTop: `1px solid ${theme.palette.primary.light}`,

    marginTop: "auto",
  },
  // necessary for content to be below app bar
}));

export default function EsgGptMainLeft({
  drawerTabs,
  drawerSearchBar,
  drawerCompanyList,
  drawerMoreButton,
  drawerAddCompanyButton,
  isgptDrawerOpen,
  handleDrawerOpen,
  handleResolution,
  children,
  ...props
}) {
  const classes = useStyles(props);
  return (
    <>
      <SwipeableDrawer
        className={classes.drawer}
        variant={"permanent"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        onClose={handleDrawerOpen}
        // variant="persistent"
        // width="lg"
        open={isgptDrawerOpen}
      >
        <Toolbar />
        {drawerTabs}
        {drawerSearchBar}
        {drawerAddCompanyButton}
        <div style={{ height: "100%", overflow: "scroll", marginTop: "5px" }}>
          {drawerCompanyList}
        </div>

        <div className={classes.buttonDiv} style={{ marginTop: "auto" }}>
          {drawerMoreButton}
        </div>
        {children && (
          <div style={{ marginTop: "auto", paddingRight: "10px" }}>
            {children}
          </div>
        )}
      </SwipeableDrawer>
    </>
  );
}
