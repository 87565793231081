export const SET_ACKNOWLEDGED = "SET_ACKNOWLEDGED";

export const SET_ORGANIZATION = "SET_ORGANIZATION";

export const SET_NEW_ORGANIZATION = "SET_NEW_ORGANIZATION";

export const SET_NEW_ORGANIZATION_LOGO = "SET_NEW_ORGANIZATION_LOGO";

export const SET_ASK_MESSAGE ="SET_ASK_MESSAGE";

export const SET_CHANGE_THEME = "SET_CHANGE_THEME"



//////////////////  CHATS CONSTANTS  ////////////////////////

export const SET_CHATS_DATA = "SET_CHATS_DATA"
export const SET_PROCEDURE = "SET_PROCEDURE"
export const SET_IS_LOADING = "SET_IS_LOADING"
