import React from "react";
import { Doughnut } from "react-chartjs-2";
import DonutChart from "react-donut-chart";
import "./donut.css";
import { useTheme } from "@material-ui/core";

function PercentageDoughnutChart({ percentage, color, emptyColor }) {
  const theme = useTheme();
  const d = 4;
  const labels = ["I", "Me", "Main"];

  const options = {
    cutout: d === 4 && "80%",
    rotation: d === 4 && 0,
    circumference: d === 4 && 360,
    borderRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
          align: "center",
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
      },

      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          maxTicksLimit: 5,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: false,
        position: "bottom",
        text: "tectxl",
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const doughnutLabel = {
    id: "doughnutLabel",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = theme.palette.primary.main;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${data.datasets[0].data[0]}`, xCoor, yCoor);
    },
  };

  const plugins = [doughnutLabel];
  const data = {
    labels: [],
    datasets: [
      {
        fill: true,
        data: [percentage, 100 - percentage],
        borderWidth: 1,
        borderColor: [color , emptyColor],
        backgroundColor: [color , emptyColor],
        tension: 0.4,
      },
    ],
  };

  return (
    <div
      style={{
        width: "200px",
        height: "200px",
        marginTop: "20px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {percentage && (
        // <div className="main">
        // {/* <DonutChart
        //   // outerRadius={0.5}
        //   // innerRadius={0.9}

        //   className="donutchart-innertext-value"
        //   height={200}
        //   width={200}
        //   legend={false}
        //   emptyOffset={0.01}
        //   // emptyColor={emptyColor}
        //   // formatValues={(values, total) => `${percentage} %`}
        //   // colors={[color]}
        //   data={[
        //     {
        //       label: "",
        //       value: percentage,
        //     },
        //     {
        //       label: "",
        //       value: 100 - percentage,
        //       isEmpty: true,
        //     },
        //   ]}
        // /> */}
        <Doughnut
          redraw={theme}
          data={data}
          options={options}
          // plugins={plugins}
        />
        // </div>
      )}
    </div>
  );
}

export default PercentageDoughnutChart;
