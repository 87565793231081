import UNIVERSAL from "../../../config";
import {
  SET_CHATS_DATA,
  SET_PROCEDURE,SET_IS_LOADING
} from "../../../constants/esgGptConstant";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";

export const setChatData = (payload) => ({
  type: SET_CHATS_DATA,
  payload,
});

export const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});
export const setProcedure = (payload) => {
  return {
    type: SET_PROCEDURE,
    payload,
  };
};

export const getChatData = (token, question, procedure,) => {
  return (dispatch) => {
    dispatch(set_loader);
    dispatch(setIsLoading(true))
    let newChat = { question: question, isLoading: true };
    dispatch(setChatData(newChat));
    const data = encrypt({
      "user-token": token,
      question,
      procedure,
    });
    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_answer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatData(responseJson?.result));
          dispatch(setIsLoading(false))
        }

        dispatch(unset_loader);
      });
  };
};
