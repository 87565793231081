import { Grid, Icon, Typography } from "@material-ui/core";
import React from "react";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import link from '../../../images/link.png'
import pdfImg from '../../../images/PDF.png'
function PublicReports({ file, handleFileRemove }) {
  // const reportData = [
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  // ];
  const fileRemove = (value) => {
    const filterFile = file.filter((v) => v.name !== value.name);
    handleFileRemove(filterFile);
  };
  return (
    <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <Grid item md={8} xs={8}>
          <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
            Publications
          </Typography>
        </Grid>
        <Grid item md={2} xs={2} align='center'>
          <Typography style={{ fontSize: "13px" }}>Open Link</Typography>
        </Grid>
        <Grid item md={2} xs={2} align='center'>
          <Typography style={{ fontSize: "13px" }}>Remove</Typography>
        </Grid>
      </Grid>
      {file.map((file) => (
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            padding: "5px",
            borderBottom: "1px solid grey",
            borderRadius: "2px",
            alignItems: "center",
          }}
        >
          <Grid item md={8} xs={8} container>
            <img style={{width:'20px',marginRight:'10px'}} src={pdfImg} alt='pdf'/>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              {file.name}
            </Typography>
            {/* <Typography
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              color: "grey",
            }}
          >
            {file.heading}
          </Typography> */}
          </Grid>
          {console.log(file)}
          <Grid item md={2} xs={2} align='center'>
            {/* <Typography style={{ fontSize: "13px" }}>
              {file.link}  link
            </Typography> */}
            {/* <a href={file} target='_blank' rel='noopener noreferrer'>a</a> */}
            <img  style={{ width: "21px",cursor:'pointer' }} src={link} alt='link' />
          </Grid>

          <Grid item md={2} xs={2} align='center'>
            <Typography style={{ fontSize: "13px" }} >
              <Icon color="action" onClick={() => fileRemove(file)}>
                <RemoveCircleOutlineIcon style={{color:'#3374b9',cursor:'pointer'}} />
              </Icon>
            </Typography>
          </Grid>
          {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
        </Grid>
      ))}
    </div>
  );
}

export default PublicReports;
