import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@material-ui/core";
import EsgGptCompanyLogo from "./EsgGptCompanyLogo";
import EsgGptFormField from "./EsgGptFormField";
import EsgGptNewCompanyDetails from "./EsgGptNewCompanyDetails";

function EsgGptCompanyForm() {
 
  const {acknowledged} = useSelector((state) => state.esgGptReducer);




  return (
    <Grid container style={{ width: "100%", height: "100%",}}>
      <Grid item md={2} style={{ height: "80%",}}>
        <EsgGptCompanyLogo />
      </Grid>
      <Grid item md={10} style={{  height:"100%" }}>
        {acknowledged ? (
          <EsgGptNewCompanyDetails height="50%" />
        ) : (
          <EsgGptFormField />
        )}
      </Grid>
      {/* {!detailAcquired && (
        <Grid
          item
          md={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "60px",
          }}
        >
          <Button
            variant="contained"
            //   color="primary"
            style={{
              backgroundColor: "#3374B9",
              borderRadius: "8px",
              color: "#EBF1F8",
              width: "40%",
              marginLeft: "auto",
            }}
            onClick={handleDetailSubmission}
            // className={classes.button}
          >
            Acquire Details
          </Button>
        </Grid>
      )} */}
    </Grid>
  );
}

export default EsgGptCompanyForm;
