import React, { useEffect } from "react";

import {
  Paper,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import leaf from "../../../images/Icon.png";
import network from "../../../images/Icon-1.png";
import piece from "../../../images/Icon-3.png";
import document from "../../../images/Document.png";
import {
  financialInsights,
  getColoredLogo,
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../utils/riskUtils/riskUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    width: "100%",
    overflow: "scroll",
    marginBottom: "4%",
    // border:"solid",
    backgroundColor: theme.palette.background.paper,
    padding: "1px",
  },
  table: {
    minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    // border:"1px solid"
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    // backgroundColor: "#F6F6F6",
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    padding: "19px 20px",
    // border:"solid",
    // textAlign:"center"
  },
  mainHeadinBackground: {
    backgroundColor: theme.palette.primary.light,
    width: "100%",

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
  typography2: {
    ...theme.typography.h5,
    color: theme.palette.primary.dark,
    padding: "10px",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  borderPrimary: {
    borderBottom:
      theme?.palette?.type === "dark"
        ? `1px solid ${theme.palette.grey[300]}`
        : `1px solid ${theme.palette.primary.light}`,
  },

  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.background.paper,
    borderBottom:
      theme.palette.type === "light" &&
      `1px solid ${theme.palette.primary.light}`,
  },
  iconBackground: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
  },
  secondaryColorMain: {
    color: theme.palette.secondary.main,
  },
  iconBackground2: {
    // border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export const OverallRiskCard = ({
  topic,
  heading,
  description,
  gpt = false,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  return (
    // <Grid item xs={4}>
    <Paper
      className={classes.backgroundColorPrimary}
      style={{
        display: "flex",
        // justifyContent: "space-between",
        // border:"solid",
        width: gpt ? "100%" : "33%",
        flexWrap: "wrap",
        // border: "solid",
        borderRadius: "8px",
        // backgroundColor: "#FFFFFF",
        flexDirection: "column",
        backgroundColor:
          theme?.palette?.type === "dark"
            ? theme.palette.background.default
            : theme.palette.background.paper,
      }}
      elevation={0}
    >
      <div>{topic && <img src={getColoredLogo(topic)} alt="logo" />}</div>
      <div style={{ padding: "10px" }}>
        {heading && (
          <Typography
            className={classes.typoColorSecondary}
            style={{ fontWeight: 700, fontSize: "14px", fontFamily: "Lato" }}
          >
            {heading}
          </Typography>
        )}
      </div>
      <Divider className={classes.borderPrimary} />
      <div style={{ marginTop: "5px", padding: "10px" }}>
        <Typography
          className={classes.primaryColor}
          style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Lato" }}
        >
          {description}
        </Typography>
      </div>
    </Paper>
    // </Grid>
  );
};

function OverallProfile(props) {
  const {
    riskEvaluator,
    getOverallData,
    brmData,
    setProfileTabNo,
    login,
    location,
  } = props;

  useEffect(() => {
    getOverallData(
      login.token,
      brmData?.organizationDetails[0]?.isin || location?.state?.isin
    );
    return () => {
      setProfileTabNo && setProfileTabNo(0);
    };
  }, [brmData?.organizationDetails]);

  const FinancialInsightsCard = ({ heading, description, year }) => {
    console.log(year);
    return (
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img style={{ width: "80px" }} src={document} alt="logo" />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              {`${heading}  ${year} -  ${year + 1} `}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
              {description}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };
  const userType = localStorage.getItem("user_type");
  return (
    <>
      {userType === "RE" && false && (
        <div className="scroll" style={{ height: "80vh", paddingBottom: 24 }}>
          <div>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                ESG Risk Area
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & identification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {riskArea.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Strong Area
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & edentification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {strongAreas.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Improvement areas
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & edentification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {improvementAreas.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Financial/Repayment Insights
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {financialInsights.map((topic) => (
                  <FinancialInsightsCard
                    heading={topic.heading}
                    description={topic.description}
                    year={
                      props.riskEvaluator?.overallData &&
                      props.riskEvaluator?.overallData[0].Year
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {riskEvaluator?.overallData && (
        <div
          className="scroll"
          style={{
            height: userType === "RA" ? "80vh" : "56vh",
            paddingBottom: 24,
          }}
        >
          <div>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                ESG Risk Area
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData[0]["Risky Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Strong Areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData[0]["Strong Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Weak Areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData[0]?.["Weak Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Improvement areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {" "}
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData[0]["Mitigation Strategies"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Financial/Repayment Insights
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {financialInsights.map((topic) => (
                  <FinancialInsightsCard
                    heading={topic.heading}
                    description={topic.description}
                    year={
                      props.riskEvaluator?.overallData &&
                      props.riskEvaluator?.overallData[0].Year
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

export default OverallProfile;
