import {
  AppBar,
  Card,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import Assessment from "./assessment/assessment";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPanel/tabPanel";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import DimentionCharts from "./dimentionChart/dimentionCharts";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import EsgScore from "../esgCompanyProfile/esgScore";
import OverallProfile from "../esgCompanyProfile/overallProfile";

const declarationStyles = makeStyles((theme) => ({
  // cardContainerDefault: {
  //   width: "100%",
  //   height: "100px",
  //   background: "#FDFDFD",
  //   border: "1px solid #C0D4E9",
  //   borderRadius: "8px",
  //   marginBottom: "16px",
  // },

  // mainHeadingDefault: {
  //   fontWeight: 500,
  //   fontSize: "16px",
  //   color: "#242424",
  //   lineHeight: "24px",
  //   letterSpacing: "0.0015em",
  //   marginBottom: 8,
  // },

  // subHeadingDefault: {
  //   fontWeight: 400,
  //   fontSize: "14px",
  //   lineHeight: "20px",
  //   color: "#BBBBBB",
  //   letterSpacing: "0.0025em",
  // },

  // cardContent: {
  //   display: "flex",
  //   flexDirection: "column",
  //   padding: "16px",
  //   // cursor: "pointer",
  // },
  // cardContainer: {
  //   backgroundColor: "#FFFFFF",
  //   // marginBottom: '15px',
  //   borderRadius: 5,
  //   height: "100%",
  // },

  // cardTypography: {
  //   fontWeight: "600",
  // },
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));
function EsgDeclaration(props) {
  const { setDeclarationTabNo } = props;
  const { declarationTabNo } = props.brmData;

  const classes = declarationStyles();
  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Company Declaration
          </Typography>
        </div>
        <div>
          <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          />
          <ShareOutlinedIcon
            style={{ color: "#3374b9", marginRight: "5px", cursor: "pointer" }}
          />
        </div>
      </div>

      <div style={{ height: "61vh" }}>
        <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={declarationTabNo}
              onChange={(e, newVal) => {
                setDeclarationTabNo(newVal);
              }}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESG Assessment</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESGC Assessment</span>}
              />
            </Tabs>
          </AppBar>
        </MuiThemeProvider>
        <TabPanel value={declarationTabNo} index={0}>
          <Assessment {...props} />
        </TabPanel>
        <TabPanel value={declarationTabNo} index={1}>
        <Assessment {...props} />
        </TabPanel>
      </div>
    </div>
  );
}

export default EsgDeclaration;
