import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const greenTheme = createTheme({
  palette: {
    primary: { main: "#47BB76" },
    secondary: { main: "#3374B9" },
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: "#47BB76",
      },
      colorSecondary: {
        color: "#3374B9",
      },
    },
  },
});

export default class CustomCheckBox extends React.PureComponent {
  render() {
    return (
      <>
        <MuiThemeProvider theme={greenTheme}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={this.props.checked} {...this.props}
          />
        </MuiThemeProvider>
      </>
    );
  }
}
