import React from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core";
import DrawerListItem from "../listItem/drawerListItem";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import menuBarUseStyles from "./menuBarStyles";
import EsgGptMainLeft from "../../screen/esgGpt/EsgMainLeft";
import EsgGptDrawerTabs from "../../widgets/esgGpt/EsgGptDrawerTabs";
import EsgGptSearchBar from "../../widgets/esgGpt/EsgGptSearchBar";
import EsgGptAddCompanyButton from "../../widgets/esgGpt/EsgGptAddCompanyButton";
import EsgMOreBUttons from "../../widgets/esgGpt/EsgGptMoreBUtton";
import EsgGptCompanyList from "../../widgets/esgGpt/EsgGptCompanyList";

function MobileDrawer({ logOut, token, user_type }) {
  const classes = menuBarUseStyles();
  const [show, setShow] = React.useState(false);
  // console.log("menu");
  const toggleDrawer = () => () => {
    // alert("de");
    setShow(!show);
  };

  const [selectedIndex, setSelectedId] = React.useState(0);
  const setSelectedIndex = (index) => {
    setSelectedId(index);
  };

  const list = () => (
    <div>
      {
        <EsgGptMainLeft
          drawerTabs={<EsgGptDrawerTabs />}
          drawerCompanyList={EsgGptCompanyList}
          drawerSearchBar={<EsgGptSearchBar />}
          drawerAddCompanyButton={<EsgGptAddCompanyButton />}
          drawerMoreButton={<EsgMOreBUttons hasIconOnly={true} />}
          width={"280px"}
        />
      }
    </div>
  );

  return (
    <div className={classes.hideShow}>
      <Grid container alignItems="center" className={classes.mainGrid}>
        <Grid item sm={3} style={{ zIndex: 1000 }}>
          {window.location.pathname !== "/esg_gpt" && (
            <IconButton onClick={toggleDrawer()}>
              <Icon className={classes.icon}>menu</Icon>
            </IconButton>
          )}
          <Drawer anchor="left" open={show} onClose={toggleDrawer()}>
            {list("right")}
          </Drawer>
        </Grid>
        {/* <Grid item sm={2}>
        <img src={logo} alt="" className={classes.img} /> 
        </Grid> */}
      </Grid>
    </div>
  );
}
export default React.memo(MobileDrawer);
