import React, { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Drawer,
  Icon,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import chevronsLeft from "../../../images/chevronsLeft.svg";
import externalLink from "../../../images/chevronsLeft.svg";
import airTable from "../../../images/chevronsLeft.svg";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RefrenceLinkList } from "./esgGptCompanyProfile/EsgGptCompanyRight";
import { linkData } from "./esgGptCompanyProfile/EsgGptCompanyRight";

const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStylesLayput = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "85vh",
    overflow: "hidden",
    // border:"solid",

    // flexDirection:"column"
  },

  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    // overflow:"hidden",
    paddingRight: theme.spacing(2),
    // border:"solid",
    // marginRight: "10px",
  },
  drawerPaper: {
    marginTop: "70px",
  },
}));

function EsgGpSideDrawer({ isgptDrawerOpen, handleDrawerOpen }) {
  const theme = useTheme();
  const classes = useStylesLayput();

  const data = [
    {
      type: "Annual Report",
      logo: airTable,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "Annual Report",
      logo: chevronsLeft,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
  ];

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={handleDrawerOpen}
        // variant="persistent"
        width="lg"
        open={isgptDrawerOpen}
      >
        <div
          style={{
            width: "500px",
            height: "100%",
            backdropFilter: "blur(10px)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Typography
              style={{
                padding: "5px 0px",
                fontWeight: 700,
                fontSize: 14,
                fontStyle: "Lato",
              }}
            >
              Companies reference link
            </Typography>
            <Typography
              onClick={handleDrawerOpen}
              style={{
                padding: "5px 0px",
                fontWeight: 400,
                fontSize: 14,
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ color: theme.palette.secondary.main }} />
            </Typography>
          </div>

          <Accordion elevation={0}>
            <AccordionSummary
              style={{
                backgroundColor: theme.palette.grey[100],
                fontWeight: 400,
                fontSize: 14,
              }}
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              }
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              Annual Report
            </AccordionSummary>

            <AccordionDetails>
              <RefrenceLinkList linkArr={linkData} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              style={{
                backgroundColor: theme.palette.grey[100],
                fontWeight: 400,
                fontSize: 14,
              }}
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              }
            >
              Sustainability reports
            </AccordionSummary>

            <AccordionDetails>
              <RefrenceLinkList linkArr={linkData} />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion>
            <AccordionSummary
              style={{
                backgroundColor: theme.palette.grey[100],
                fontWeight: 400,
                fontSize: 14,
              }}
              expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }}/>}
            >
              ESG Reports
            </AccordionSummary>

            {data.map((report) => (
              <AccordionDetails>
                <div style={{ alignItems: "center" }}>
                  <img
                    style={{
                      objectFit: "contain",
                      width: "90px",
                      height: "90px",
                    }}
                    src={report.logo}
                    alt="logo"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    padding: "5px 0px 5px 4px",
                  }}
                >
                  <Typography style={{ fontWeight: 500 }}>
                    {report.topic}
                  </Typography>
                  <Typography style={{ color: "#6F7170", fontSize: 14 }}>
                    {report.link}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    onClick={() => {
                      window.open(report.link);
                    }}
                  >
                    <img
                      style={{ objectFit: "contain", width: "20px" }}
                      src={externalLink}
                      alt="openLink"
                    />
                  </Icon>
                </div>
              </AccordionDetails>
            ))}
          </Accordion> */}
        </div>
      </Drawer>
    </>
  );
}

export default EsgGpSideDrawer;
