const cryptoJS = require("crypto-js");
const encrypt = (object) => {
  //env secret key get in next.js client side.
const jwt_secret = '5e3d7db17478a1024a3977dd';

  //encrypt data using cryptoJS.
  const data = cryptoJS.AES.encrypt(
    JSON.stringify(object),
    jwt_secret
  ).toString();

  return data;
};

export default encrypt;
