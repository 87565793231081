import { Icon, Typography } from "@material-ui/core";
import React, { useState } from "react";

import comp from "../../../images/comp.png";
import comp1 from "../../../images/comp1.png";
import comp2 from "../../../images/comp2.png";
import comp3 from "../../../images/comp3.png";
import comp4 from "../../../images/comp4.png";
import Documents from "./documents";
// import comp5 from "../../../images/comp5.png";

function PeerDocuments(props) {
  const peers = [
    {
      name: "Globex Corporation",
      img: comp,
    },
    {
      name: "Ollivander's Wand Shop",
      img: comp1,
    },
    {
      name: "Soylent Corp",
      img: comp2,
    },
    {
      name: "Bubba Gump",
      img: comp3,
    },
    {
      name: "Stark Indutries",
      img: comp4,
    },
  ];

  const [showPeerDocs, setShowpeerDocs] = useState(false);
  const [name, setName] = useState("");

  return (
    <>
      {!showPeerDocs ? (
        <div style={{ height: "63vh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                peers
              </Typography>
            </div>
            <div>
              <Icon style={{color:'#3374B9',cursor:'pointer'}} >
                more_vert

              </Icon>
            </div>
          </div>
          <div className="scroll" style={{ height: "61vh" }}>
            {peers.map((peer) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                  padding: 5,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={peer.img}
                    alt="sf"
                  />
                  <Typography style={{ marginLeft: "16px" }}>
                    {peer.name}
                  </Typography>
                </div>
                <div>
                  <Typography
                    onClick={() => {
                      setShowpeerDocs(!showPeerDocs);
                      setName(peer.name);
                    }}
                    style={{ fontSize: "24px",cursor:'pointer' }}
                  >{`>`}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Documents
          showPeerDocs={showPeerDocs}
          setShowpeerDocs={setShowpeerDocs}
          companyName={name}
          {...props}
        />
      )}
    </>
  );
}

export default PeerDocuments;
