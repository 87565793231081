import React from "react";
import { Typography } from "@material-ui/core";

function CustomCompaniesRange({ hasTitle = true }) {
  const prop = {
    fullwidth: false,
    peer_companies: [
      {
        _id: "64xVN4sB-39pvtd-BaX8",
        company_name: "Company A",
        score: 21.230632878151262,
        environment_score: 10.416294642857144,
        social_score: 0.036893382352941186,
        governance_score: 0.07125,
      },
      {
        _id: "-4xMLosB-39pvtd-M4CE",
        company_name: "Company B",
        score: 98.5231244881126,
        environment_score: 0.004301470588235295,
        social_score: 0.013235294117647059,
        governance_score: 0.0029411764705882353,
      },
      {
        _id: "84wFJIsB-39pvtd-lXLH",
        company_name: "Company C",
        score: 47.21033699070618,
        environment_score: 0.004301470588235295,
        social_score: 0.013235294117647059,
        governance_score: 0.0029411764705882353,
      },
      {
        _id: "p4wxN4sB-39pvtd-bqQ5",
        company_name: "Company D",
        score: 66.0760345777863,
        environment_score: 0.018281250000000002,
        social_score: 0.05625,
        governance_score: 0.0125,
      },
    ],
    score: "47.21",
    lowerRange: "21.23",
    upperRange: "98.52",
  };
  const {
    lowerRange,
    upperRange,
    score,
    companyScores,
    fullwidth,
    peer_companies,
  } = prop;
  let mainScore = score || 0;
  const colorRisk = [
    "#6AC346",
    "#F04187",
    "#FF7675",
    "#FF6A00",
    "#8246AF",
    "#6AC346",
    "#F04187",
    "#FF7675",
    "#FF6A00",
    "#8246AF",
  ];

  const colorLenovo = [
    "#FAECEB",
    "#C9D0F0",
    "#D9C1D8",
    "#EAEEF5",
    "#F1E1ED",
    "#C9D0F0",
  ];

  const colors = hasTitle ? colorRisk : colorLenovo;
  const companies = [
    "company1",
    "company2",
    "company3",
    "company4",
    "company5",
  ];
  // console.log("peer_companies", peer_companies);
  return (
    <div id="chartLenovo">
      <div style={{ border: "none", height: "44vh", position: "relative" }}>
        {hasTitle && (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 14,
              marginTop: "10px",
            }}
          >
            Peer Group Range
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: "4%",
            alignItems: "center",
          }}
        >
          {peer_companies &&
            peer_companies.map(({ company_name }, i) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  // marginLeft: "4%",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: colors[i],
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    margin: "0px 2px 0px 8px",
                  }}
                ></span>
                <Typography style={{ fontSize: 12 }}>{company_name}</Typography>
              </div>
            ))}
        </div>

        <div
          style={{
            borderRight: "3px dashed #5198E0",
            height: "50%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "4%",
            right: "4px",
            // padding: "0px 1px",
            backgroundColor: "white",
            color: "#5198E0",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
            display: "flex",
            justifyContent: "center",
          }}
        ></div>

        <div
          style={{
            borderLeft: "3px dashed #5198E0",
            height: "50%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "-46%",
            // padding: "0px 1px",
            backgroundColor: "white",
            color: "#5198E0",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
            display: "flex",
            justifyContent: "center",
          }}
        ></div>

        {/* ///////////////////////////// */}
        <div
          style={{
            borderLeft: "2px solid #5198E0",
            height: "16%",
            width: "1px",
            // marginLeft: "5%",
            position: "relative",
            top: "-48%",
            // padding: "0px 1px",
            backgroundColor: "white",
            zIndex: 1,
            //pass the score value here
            marginLeft: `${4 + 0.9 * mainScore}% `,
          }}
        >
          <span
            style={{
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#5198E0",
              position: "absolute",
              bottom: 0,
              marginLeft: "2px",
            }}
          >
            <Typography
              style={{ color: "white", fontSize: "12px", padding: "1px 6px" }}
            >
              {`${mainScore}%`}
            </Typography>
          </span>
        </div>

        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            top: "-112%",
          }}
        >
          <div
            style={{
              border: "1px solid lightgrey",
              // borderLeft: "1px solid lightgrey",
              // borderRight: "1px solid lightgrey",
              // borderBottom: "1px solid lightgrey",
              height: "25%",
              margin: "0% 4% 0% 5%",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              borderLeft: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
              borderBottom: "1px solid lightgrey",
              height: "25%",
              margin: "0% 4% 0% 5%",
              position: "relative",
            }}
          >
            {/* <div
                style={{
                  borderRight: "3px solid red",
                  height: "200%",
                  width: "1px",
                  // marginLeft: "5%",
                  position: "relative",
                  top: "-100%",
                  padding: "0px 2px",
                  backgroundColor: "white",
                  color: "#5198E0",
                  zIndex: 100,
                  //pass the score value here
                  left: `${score -1}% `,
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div> */}

            {peer_companies &&
              peer_companies.map(({ score }, i) => {
                return (
                  <div
                    key={score + i}
                    style={{
                      borderRight: `8px solid ${colors[i]}`,
                      height: "200%",
                      width: "1px",
                      // marginLeft: "5%",
                      position: "absolute",
                      top: "-100%",
                      // padding: "0px 1px",
                      backgroundColor: "white",
                      color: "#5198E0",
                      zIndex: 100,
                      //pass the score value here make the othe number to 0.3 if used fullwidth
                      left: `${fullwidth ? score - 0.3 : score - 2}% `,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></div>
                );
              })}

            {/* {companyScores.map((score, i) => (
              <div
                style={{
                  borderLeft: "2px solid red",
                  height: "50%",
                  width: "1px",
                  // marginLeft: "5%",
                  position: "relative",
                  top: "-46%",
                  // padding: "0px 2px",
                  backgroundColor: "white",
                  color: "#5198E0",
                  zIndex: 1,
                  //pass the score value here
                  left: `${score - 0.3}% `,
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>
            ))} */}
          </div>
          <div
            style={{
              zIndex: 0,
              border: "1px dashed grey",
              // backgroundColor: "#8246AF",
              opacity: 1,
              height: "50%",
              margin: "0% 5%",
              position: "relative",
              //pass the value range from left
              marginLeft: `${4 + 0.9 * lowerRange}% `,
              //pass the value range from right
              marginRight: `${5 + 0.9 * (100 - upperRange)}% `,
              bottom: "50%",
            }}
          >
            {/* //new */}
            {/* <div
              style={{
                borderRight: "2px dashed #5198E0",
                height: "50%",
                width: "1px",
                // marginLeft: "5%",
                position: "relative",
                top: "4%",
                right: "4px",
                padding: "0px 2px",
                backgroundColor: "white",
                color: "#5198E0",
                zIndex: 1,
                //pass the score value here
                marginLeft: `${4 + 0.9 * 50}% `,
                display: "flex",
                justifyContent: "center",
              }}
            ></div> */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0% 2% 0% 4%",
              position: "relative",
              bottom: "30%",
            }}
          >
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              0%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              10%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              20%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              30%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              40%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              50%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              60%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              70%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              80%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              90%
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              100%
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomCompaniesRange;
