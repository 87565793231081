import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { Paper, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backgroundColorPrimary: {
    backgroundColor: theme.palette.background.paper,
  },
}));

// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options1 = {
  plugins: {
    title: {
      display: false,
      text: "Total Energy (MegaJoules)",
      //   position:"left"
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["Current Financial Year", "Previous Financial Year"];

const labels1 = ["ABC Info", "DEF Info", " GH Info", "BRBD Info", "Acc Infro", "Cap Info"];

export const data1 = {
  labels: labels1,
  datasets: [
    {
      label: "Quarter 1",
      data: [200, 100, 300, 500, 222, 333],
      backgroundColor: "#F0C7BF",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 2",
      data: [200, 100, 300, 233, 300, 100],
      backgroundColor: "#D9C1D8",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 3",
      data: [500, 400, 300, 100, 200, 150],
      backgroundColor: "#FAECEB",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 4",
      data: [300, 100, 500, 200, 300, 300],
      backgroundColor: "#C9D0F0",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },  
  ],
};

export const data = {
  labels,
  datasets: [
    {
      label: "Quarter 1",
      data: [200, 100, 300],
      backgroundColor: "rgba(240, 199, 191, 1)",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 2",
      data: [200, 100, 300],
      backgroundColor: "rgba(217, 193, 216, 1)",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 3",
      data: [500, 400, 300],
      backgroundColor: "rgba(250, 236, 235, 1)",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
    {
      label: "Quarter 4",
      data: [300, 100, 500],
      backgroundColor: "rgba(201, 208, 240, 1)",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 10,
    },
  ],
};

const options = {
  plugins: {
    title: {
      display: false,
      text: "Total Energy (MegaJoules)",
      color: "red",

      //   position:"left"
    },
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 12,
        color: "red",
      },
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        color: "red",
      },
    },
    y: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: true,
        color: "#EAEEF5",
      },
      ticks: {
        color: "red",
      },
    },
  },
};

export default function Charts({ isPeer = false, length }) {
  const classes = useStyles();
  const theme = useTheme();

  const options = {
    plugins: {
      title: {
        display: false,
        text: "Total Energy (MegaJoules)",
        color: theme.palette.primary.main,

        //   position:"left"
      },
      legend: {
        position: "top",
        
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: length === 1 ? 6 : 10,
          color: theme.palette.primary.main,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: theme.palette.primary.main,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: true,
          color: theme.palette.primary.contrastText,
        },
        ticks: {
          color: theme.palette.primary.main,
          maxTicksLimit: 5,
        },
      },
    },
    layout: {
      padding: 5,
    },
  };

  const options1 = {
    plugins: {
      title: {
        display: false,
        text: "Total Energy (MegaJoules)",
        //   position:"left"
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: length === 1 ? 6 : 10,
          color: theme.palette.primary.main,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: theme.palette.primary.main,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: true,
          color: theme.palette.primary.contrastText,
        },
        ticks: {
          color: theme.palette.primary.main,
          maxTicksLimit: 5,
        },
      },
    },
    layout: {
      padding: 5,
    },
  };
  return (
    <Paper
      className={classes.backgroundColorPrimary}
      elevation={0}
      style={{ height: "100%", width: "100%", borderRadius: "8px" }}
    >
      <Bar
        style={{ padding: "10px" }}
        options={isPeer ? options1 : options}
        data={isPeer ? data1 : data}
      />
    </Paper>
  );
}
