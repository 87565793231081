import encrypt from "../shared/sharedActions";
import UNIVERSAL from "../../../config/config";
import {set_snack_bar} from "../snackbar/snackbar_action";
import { set_loader, unset_loader } from "../loader/loader_action";
export function addMember(
  token,
  bankId,
  organization_id,
  name,
  email,
  role,
  category,
  type,
  url
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      organization_id,
      name,
      email,
      role,
      category,
      type,
      url,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/add_member", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewMember(token, organization_id));
          dispatch(unset_loader());
        } else {
          dispatch(unset_loader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const setMembers = (payload) => {
  return {
    type: "SET_MEMBERS",
    payload,
  };
};
export function viewMember(token, organization_id) {
  return (dispatch) => {
    dispatch(set_loader())
    const data = encrypt({
      "user-token": token,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/view_members", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setMembers(responseJson.result));
          dispatch(unset_loader());
        } else {
          dispatch(setMembers([]));
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function editMember(token, user_id, organization_id, role, category) {
  return (dispatch) => {
    dispatch(set_loader())
    const data = encrypt({
      "user-token": token,
      user_id,
      role,
      category,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/edit_member", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(viewMember(token, organization_id));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
