import React from "react";
import { connect } from "react-redux";
import ResetPassword from "../../components/screen/resetPassword/resetPassword";
import { resetForgetPassword } from "../../redux/actions/login/loginAction";

export class ResetPasswordCon extends React.PureComponent {
  render() {
    return <ResetPassword {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    resetForgetPassword: (password, reset_code) => {
      dispatch(resetForgetPassword(password, reset_code));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCon);
