import {
  SET_BRM_MAIN_TAB_NO,
  SET_BRM_SUB_TAB_NO,
  SET_MEMBERS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
  SET_SECTOR_INDUSTRIES,
  SET_SELECTED_SECTOR_INDUSTRIES,SET_DECLARATION_TAB_NO,
  SET_CONTROVERSY_TAB_NO,
  SET_PROFILE_TAB_NO,
} from "../../../constants/brmConstants";
import { SET_SURVEY_QUESTIONS } from "../../../constants/corporateSurveyConstants";

const initial_state = {
  brmMainTabNo: 0,
  brmSubTabNo: 0,
  declarationTabNo:0,
  controversyTabNo:0,
  profileTabNo:0,
  sectorIndustries: [],
  organizationDetails: "",
  members: "",
  organizationsList:'',
  surveyQuestions:[]
};

export default function brmReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_BRM_MAIN_TAB_NO:
      return (state = { ...state, brmMainTabNo: action.payload });
    case SET_BRM_SUB_TAB_NO:
      return (state = { ...state, brmSubTabNo: action.payload });
    case SET_DECLARATION_TAB_NO:
      return (state = { ...state, declarationTabNo: action.payload });
    case SET_CONTROVERSY_TAB_NO:
      return (state = { ...state, controversyTabNo: action.payload });
    case SET_PROFILE_TAB_NO:
      return (state = { ...state, profileTabNo: action.payload });
    case SET_SECTOR_INDUSTRIES:
      return (state = { ...state, sectorIndustries: action.payload });
    case SET_SELECTED_SECTOR_INDUSTRIES:
      return (state = { ...state, selectedSectorIndustries: action.payload });
    case SET_ORGANIZATION_DETAILS:
      return (state = { ...state, organizationDetails: action.payload });
    case SET_MEMBERS:
      return (state = { ...state, members: action.payload });
    case SET_ORGANIZATIONS_LIST:
      return (state = { ...state, organizationsList: action.payload });
    case SET_SURVEY_QUESTIONS:
      return (state = { ...state, surveyQuestions: action.payload });
    default:
      return state;
  }
}
