import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const AppBarUseStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      // zIndex:999
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  title: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  border: {
    padding: '2px',
    backgroundImage: 'linear-gradient(to right, #47BB76, #3374B9)',
    marginLeft: 15,
    borderRadius: 50,
    height: 44,
  },
  name: {
    padding: '0.5rem 0.6rem 0.5rem 1rem',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    cursor: 'pointer',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notificationItem: {
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#EBF1F8',
    },
  },
  userImage: {
    // border: '2px solid #32A05F',
    border: `2px solid ${theme.palette.primary.green}`,
    padding: 5,
    borderRadius: '50%',
    marginLeft: 15,
    cursor: 'pointer',
  },
  mainDiv: { padding: 25, maxWidth: 520, minWidth: 520 },
  headerGrid: { borderBottom: '1px solid #DADBE6' },
  subGrid: { borderBottom: '3px solid #47BB76' },
  avatar: {
    backgroundColor: '#F7CBC7',
    width: 45,
    height: 45,
  },
  activitiesGrid: {
    textAlign: 'center',
    borderTop: '1px solid #DADBE6',
    marginTop: 15,
    paddingTop: 5,
  },
  activityTypo: { cursor: 'pointer' },
}));

export default AppBarUseStyles;
