import React from "react";

import { Typography, makeStyles, useTheme } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

import settingDark from "../../../images/Dashboard-Left-Icons/SVG/settings.svg";
import settingLight from "../../../images/Dashboard-Left-Icons/SVG/settingsLight.svg";

import thumbUpDark from "../../../images/Dashboard-Left-Icons/SVG/thumbs-up.svg";
import thumbUpLight from "../../../images/Dashboard-Left-Icons/SVG/thumbs-upLight.svg";
import errorCircleDark from "../../../images/Dashboard-Left-Icons/SVG/info.svg";
import errorCircleLight from "../../../images/Dashboard-Left-Icons/SVG/infoLight.svg";
import profileOutDark from "../../../images/Dashboard-Left-Icons/SVG/user.svg";
import profileOutLight from "../../../images/Dashboard-Left-Icons/SVG/userLight.svg";
import moonDark from "../../../images/Dashboard-Left-Icons/SVG/moon.svg";
import moonLight from "../../../images/Dashboard-Left-Icons/SVG/moonLight.svg";

import useHoverEffect from "../../../customHooks/getHoveringEffects";
import { useDispatch, useSelector } from "react-redux";
import { setChangeTheme } from "../../../redux/actions/gpt/esgGptAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // border: "1px solid #EBF3FB",
    padding: "10px 20px 10px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px 5px 5px",
    },
    cursor: "pointer",
    "&:hover": {
      ...theme.typography.body1,
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.dark,
      // fontSize: "14px",
      // fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    // border:"solid",
    // textAlign:"center"
  },
}));

export default function EsgMOreBUttons({ hasIconOnly = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasDefaultTheme } = useSelector((state) => state.esgGptReducer);
  const theme = useTheme();

  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();

  const settingSvg =
    theme.palette.type === "light" ? settingDark : settingLight;
  const thumbUp = theme.palette.type === "light" ? thumbUpDark : thumbUpLight;
  const errorCircle =
    theme.palette.type === "light" ? errorCircleDark : errorCircleLight;
  const profileOut =
    theme.palette.type === "light" ? profileOutDark : profileOutLight;
  const moon = theme.palette.type === "light" ? moonDark : moonLight;

  const moreButtonArr = [
    { src: settingSvg, name: "Settings" },
    { src: thumbUp, name: "Feedback" },
    { src: errorCircle, name: "About & Policy" },
    { src: profileOut, name: "User Name" },
    { src: moon, name: "Light Mode" },
  ];
  return moreButtonArr.map((data, index) => (
    <div
      className={classes.root}
      // style={{
      //   // width: "100%",
      //   display: "flex",
      //   justifyContent: hasIconOnly ? "flex-start" : "center",
      //   alignItems: "center",
      //   // border: "1px solid #EBF3FB",
      //   padding: "5px 20px 5px 10px",
      //   cursor: "pointer",
      //   // backgroundColor: selectedItem === index ? "#EBF1F8" : "#FBFBFB",
      // }}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <img
        src={data.src}
        style={{
          marginRight: "5px",
          height: "1rem",
          width: "1.5rem",
        }}
        alt="pdf icon"
      />

      {hasIconOnly && (
        <Typography className={classes.typography}>{data.name}</Typography>
      )}
      {data.name === "Light Mode" && (
        <div style={{ marginLeft: "auto" }}>
          <Switch
            value={hasDefaultTheme}
            onClick={() => dispatch(setChangeTheme(!hasDefaultTheme))}
          />
        </div>
      )}
    </div>
  ));
}
