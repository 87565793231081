import React, { useEffect, useState } from "react";

function useGeoLocation() {
  const [location, setLocation] = useState({
    loaded: false,
    coords: { lat: "", long: "" },
  });
  const onSuccess = (location) => {
    setLocation((prev) => {
      return {
        ...prev,
        loaded: true,
        coords: {
          lat: location.coords.lattitude,
          long: location.coords.longitude,
        },
      };
    });
  };
  useEffect(() => {
    if (!("geolocation" in navigator)) {
        setLocation((prev) => {
            return {
              ...prev,
              loaded: true,
              err: {
                message:'errrrrrr',
              },
            };
          });
    } else {
      navigator.geolocation.getCurrentPosition((position) =>
        setLocation((prev) => {
          return {
            ...prev,
            loaded: true,
            coords: position.coords,
          };
        })
      );
    }
  }, []);
  return location;
}

export default useGeoLocation;
