import { SET_CORPORATE_QUESTIONS ,RESET_CORPORATE,SET_CORPORATE_ANSWERS} from "../../../constants/corporateSurveyConstants";

const initial_state = {
    corporateQuestions: '',
    corporateAnswers:'',
  };
  
  export default function corporateReducer(state = initial_state, action) {
    switch (action.type) {
      case SET_CORPORATE_QUESTIONS:
        return (state = { ...state, corporateQuestions: action.payload });
      case SET_CORPORATE_ANSWERS:
        return (state = { ...state, corporateAnswers: action.payload });
      case RESET_CORPORATE:
        return (state = initial_state);
      default:
        return state;
    }
  }
  