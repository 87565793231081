import React, { useEffect, useState } from "react";
import RadioButton from "../../UI/radioButton/radioButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { brmRightStyles } from "../riskAssessment/brmDataRight.js/brmDataRight";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import useGeoLocation from "../../../customHooks/getGeoLocation";
import GoogleMaps from "./googleMaps";

function SurveyQuestions(props) {
  const { getSurveyeQuestions, addSurveyeQuestions, login, brmData } = props;
  const classes = brmRightStyles();

  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [questions, setQuestions] = useState(brmData?.surveyQuestions);
  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = React.useState({});

  const location = useGeoLocation();
  // console.log("location", location);

  useEffect(() => {
    getSurveyeQuestions(
      login.token,
      brmData?.organizationDetails[0]?._id ||
        localStorage.getItem("organization_id")
    );
  }, []);
  const handleChange = (event, id) => {
    setValue((preValue) => ({ ...preValue, [id]: event.target.value }));
  };

  const handleAddquestion = () => {
    let question = {
      order: brmData?.surveyQuestions.length + 1,
      category: "custom question",
      type: data.type,
      question: data.question,
      answers: data.options,
    };
    // const copy = [...brmData?.surveyQuestions];
    // copy.push({
    //   order: brmData?.surveyQuestions.length + 1,
    //   category: "",
    //   type: data.type,
    //   question: data.question,
    //   answers: data.options,
    // });
    addSurveyeQuestions(
      login.token,
      brmData?.organizationDetails[0]?._id ||
        localStorage.getItem("organization_id"),
      question
    );
    // setQuestions(copy);
    setIsAddQuestionOpen(false);
    setData({
      type: "",
      question: "",
      options: [["", 0]],
    });
  };

  const addQuestionDialog = () => {
    return (
      <Dialog fullWidth open={isAddQuestionOpen} minWidth="md" maxWidth="md">
        <DialogTitle>
          <Grid
            style={{ display: "flex", justifyContent: "space-between" }}
            container
            justifyContent="center"
            className={classes.mainGrid}
          >
            {/* <Grid item xs={11}> */}
            <Typography style={{ fontWeight: 500 }}>Add Question</Typography>
            {/* </Grid> */}
            {/* <Grid  item xs={1}> */}
            <CloseOutlinedIcon onClick={() => setIsAddQuestionOpen(false)} />
            {/* </Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}></Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Type of Question
              </Typography>

              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={data.type}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, type: e.target.value };
                  });
                }}
              >
                {[
                  "radio",
                  "textField",
                  "multi select",
                  "file",
                  "location",
                  "date",
                ].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid4}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  color: "#9A9A9A",
                }}
              >
                Enter Question
              </Typography>
              <CustomTextfield
                name="userName"
                label="Question"
                variant="outlined"
                size="small"
                fullWidth
                value={data.question}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, question: e.target.value };
                  });
                }}
              />
            </Grid>
            {/* {data.type !== "textField" && (
              <Grid item xs={12} md={12} className={classes.grid5}>
                <Typography>option 1</Typography>
                <CustomTextfield
                  name="userRole"
                  label="option"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data.options}
                  onChange={(e) => {
                    setData((prev, j) => {
                      const opt =[...prev.options]
                      return {
                        ...prev,
                        options: opt.push([e.target.value, j + 1]),
                      };
                    });
                  }}
                />
              </Grid>

            )} */}
            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") &&
              data.options.map((option, index) => (
                <Grid item xs={12} md={12} className={classes.grid5}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#9A9A9A",
                    }}
                  >
                    option {`${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomTextfield
                      name="userRole"
                      label="option"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={option[0]}
                      onChange={(e) => {
                        const copy = { ...data };
                        const copyOptions = [...copy.options];
                        copyOptions[index][0] = e.target.value;
                        setData(copy);
                      }}
                    />
                    <RemoveCircleOutlineOutlinedIcon
                      style={{
                        color: "#3374b9",
                        display: "inline",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const copy = { ...data };
                        copy?.options.splice(index, 1);
                        setData(copy);
                      }}
                    />
                  </div>
                </Grid>
              ))}

            {(!data.type ||
              data.type === "radio" ||
              data.type === "multi select") && (
              <Button
                onClick={() => {
                  const copy = { ...data };
                  console.log(copy.options.length)
                  copy?.options.push(["", copy.options.length]);
                  setData(copy);
                }}
              >
                add option
              </Button>
            )}

            {data.type === "file" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="file"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "date" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Select File
                </Typography>
                <CustomTextfield
                  name="userName"
                  variant="outlined"
                  type="date"
                  size="small"
                  fullWidth
                  value={""}
                />
              </Grid>
            )}
            {data.type === "location" && (
              <Grid item xs={12} md={12} className={classes.grid4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  Grant permission to access location
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    color: "#9A9A9A",
                  }}
                >
                  latitude: {location.coords.latitude}, longitude:{" "}
                  {location.coords.longitude}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs={12} md={12} className={classes.grid5}>
              <Typography>option 1</Typography>
              <CustomTextfield
                name="userRole"
                label="option"
                variant="outlined"
                size="small"
                fullWidth
                // value={role}
                // onChange={(e) => setRole(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            {/* <Grid item xs={12} md={12} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Grid> */}

            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setIsAddQuestionOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleAddquestion}
                disabled={!data.question}
              >
                Add Question
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {isAddQuestionOpen && addQuestionDialog()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Survey Questions
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            // variant="outlined"
            size="small"
            // startIcon={<Add />}
            // color="primary"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setIsAddQuestionOpen(!isAddQuestionOpen)}
          >
            Add Question
          </Button>
          <Icon style={{ color: "#3374b9", cursor: "pointer" }}>
            <ShareOutlinedIcon />
          </Icon>
        </div>
      </div>
      <div className="scroll" style={{ height: "61vh" }}>
        {/* <Card style={{ padding: '5px', margin: '5px' }}> */}
        {brmData?.surveyQuestions.map((que, i) => {
          return (
            <div key={i}>
              <br />
              <Grid container style={{ width: "100%" }}>
                <Grid item md={1}>
                  <Typography className={classes.qno}>Q{i + 1}.</Typography>
                </Grid>
                <Grid item md={11}>
                  <Typography>{que?.question}</Typography>
                  <FormControl on component="fieldset">
                    {!que?.type || que?.type === "radio" ? (
                      <RadioGroup
                        value={value[que?.order]}
                        onChange={(e) => handleChange(e, que?.order)}
                        name={que?._id + ""}
                      >
                        {que?.answers &&
                          que?.answers.map((ans, j) => {
                            return (
                              <div key={j} style={{ width: "100%" }}>
                                <FormControlLabel
                                  style={{
                                    width: "100%",
                                    padding:
                                      que.type === "textField" && "15px 0px",
                                  }}
                                  value={ans[0]}
                                  control={
                                    <>
                                      {(!que.type || que.type === "radio") && (
                                        // <TextField
                                        //   // className={clsx(classes.root, classes.checked)}
                                        //   onChange={(e) => {
                                        //     // checkBoxChange(e, i, j);
                                        //   }}
                                        //   name={que.id + ""}
                                        //   id={ans[1] + ""}
                                        // />
                                        <RadioButton
                                          value={ans[1]}
                                          id={ans[0] + ""}
                                        />
                                        //  <FormControlLabel value="female" control={<Radio />} label="Female" />
                                      )}
                                    </>
                                  }
                                  label={ans[0]}
                                  // onChange={(e) => {
                                  //   addSurvey(e, i);
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </RadioGroup>
                    ) : (
                      <>
                        {que.type === "textField" && (
                          <div style={{ padding: "15px 0px" }}>
                            <CustomTextfield
                              style={{
                                width: "50vw",
                              }}
                              name="userName"
                              label="Answer"
                              variant="outlined"
                              size="small"
                              fullWidth
                              // value={data.question}
                            />
                          </div>
                        )}
                        {que.type === "multi select" && (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ padding: "15px 0px" }}
                          >
                            {/* <Typography
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "5px",
                                        color: "#9A9A9A",
                                      }}
                                    >
                                      Select File
                                    </Typography> */}

                            <Select
                              defaultValue={"Select an Option"}
                              style={{
                                border: "1px solid #C0D4E9",
                                backgroundColor: "#FEFEFE",
                                borderRadius: "8px",
                                height: "40px",
                                width: "50vw",
                                marginTop: "15px",
                              }}
                              // value={collect.value}
                              onChange={(event) => {
                                const {
                                  target: { value },
                                } = event;

                                // setCollect((prev) => {
                                //   return {
                                //     ...prev,
                                //     question_id: que?._id,
                                //     value: value,
                                //   };
                                // });
                                // setDisclosureSort(value);
                              }}
                              input={<OutlinedInput />}
                            >
                              {que?.answers.map((item) => {
                                return (
                                  <MenuItem key={item[0]} value={item[0]}>
                                    {item[0]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        )}
                        {que.type === "location" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: `400px`,
                              width: "800px",
                            }}
                          >
                            <Typography>
                              {" "}
                              latitude: {location.coords.latitude}, longitude:{" "}
                              {location.coords.longitude}
                            </Typography>
                            {/* <GoogleMaps {...props}/> */}
                          </div>
                        )}
                        {que.type === "date" && (
                          <Grid item xs={12} md={12} className={classes.grid4}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                marginBottom: "5px",
                                color: "#9A9A9A",
                              }}
                            >
                              Select File
                            </Typography>
                            <CustomTextfield
                              name="date"
                              variant="outlined"
                              type="date"
                              size="small"
                              fullWidth
                              value={""}
                            />
                          </Grid>
                        )}
                        {que.type === "file" && (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ padding: "15px 0px" }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                marginBottom: "5px",
                                color: "#9A9A9A",
                              }}
                            >
                              Select File
                            </Typography>
                            <CustomTextfield
                              name="userName"
                              variant="outlined"
                              type="file"
                              size="small"
                              fullWidth
                              // value={collect.value}
                              onChange={(event) => {
                                const {
                                  target: { value },
                                } = event;

                                // setDisclosureSort(value);
                              }}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </FormControl>
                  <hr></hr>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <br /> <br />
        {/* <Button
        variant="contained"
        color="primary"
        style={{ float: "right", margin: "0px 20px 20px" }}
        onClick={() => {
          // charting();
          // calculate();
        }}
      >
        Submit
      </Button> */}
        {/* </Card> */}
      </div>
    </div>
  );
}

export default SurveyQuestions;
