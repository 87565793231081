import React from "react";
import { connect } from "react-redux";
import { getCompanyData, getControversyLinks, getElasticOrganizations, publishLinks, updateCompanyData, updateControversyType } from "../../redux/actions/riskdashboard/riskDashboardActions";
import RiskDocument from "../../components/widgets/riskDashboard/riskDocument";
import { getControversyData, getOverallData } from "../../redux/actions/riskEvaluator/riskEvaluatorActions";

function RiskDocumentCont(props) {
  return <RiskDocument {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  riskDashboard:store.riskDashboard,
  controversyData: store.riskEvaluator.controversyData,
  riskEvaluator:store.riskEvaluator
});

export const mapDispatchToProps = (dispatch) => ({
    getElasticOrganizations: (token ,limit,starting_after) => {
    dispatch(getElasticOrganizations(token ,limit,starting_after));
  },
  getCompanyData: (token, company_id) => {
    dispatch(getCompanyData(token, company_id));
  },
  getControversyLinks: (token,isin) => {
    dispatch(getControversyLinks(token,isin));
  },
  getControversyData: (token,isin) => {
    dispatch(getControversyData(token,isin));
  },
  updateCompanyData: (token, company_id,answers,setAdminTabNo) => {
    dispatch(updateCompanyData(token, company_id,answers,setAdminTabNo));
  },
  updateControversyType: (token, _id, controversy_type,isin) => {
    dispatch(updateControversyType(token, _id, controversy_type,isin));
  },
  publishLinks:(token, ids,isin) => {
    dispatch(publishLinks(token, ids,isin));
  },
  getOverallData: (token,company_isin) => {
    dispatch(getOverallData(token,company_isin));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RiskDocumentCont);
