import React, { useState } from "react";
import {
  AppBar,
  Card,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPanel/tabPanel";
import Assessment from "../esgDeclaration/assessment/assessment";
import NewsArticle from "./newsArticle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import ControversyProfile from "./controversyProfile";

const controversyStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));
function Controversy(props) {
  const { setControversyTabNo } = props;
  const { controversyTabNo } = props.brmData;

  const classes = controversyStyles();
  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Controversy
          </Typography>
        </div>
        <div>
          <InfoOutlinedIcon style={{ color: "#3374b9", marginRight: "12px",cursor: "pointer" }} />
          <ShareOutlinedIcon style={{ color: "#3374b9", marginRight: "5px",cursor: "pointer" }} />
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={controversyTabNo}
            onChange={(e, newVal) => {
              setControversyTabNo(newVal);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Company Profile</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>News Article</span>}
            />
            {/* <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Assessment</span>}
            /> */}
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      
      <TabPanel value={controversyTabNo} index={0}>
        <ControversyProfile {...props} />
      </TabPanel>
      <TabPanel value={controversyTabNo} index={1}>
        <NewsArticle {...props} />
      </TabPanel>
      {/* <TabPanel value={controversyTabNo} index={2}>
        <Assessment {...props} />
      </TabPanel> */}
    </div>
  );
}

export default Controversy;
