import React, { useState } from "react";
import { useTheme } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import CustomButton from "../../UI/button/button";
import logo from "../../../images/logo.svg";
import LenovoLogo from "../../../images/lenevoSvg.svg";
import MagPieLogo from "../../../images/gieom/MagPieLogo.png";
import UserProfileCard from "../../widgets/userProfileCard/userProfileCard";
import moment from "moment";
// import notificationDrawer from './NotificationDrawer'
import AppBarUseStyles from "./AppBarStyles";
import Button from "@material-ui/core/Button";

function CustomAppBar(props) {
  const theme = useTheme();
  const { isOpen, login, logOut } = props;

  console.log(logOut);

  const { token, name, isLoggedIn, email } = { ...login };
  const classes = AppBarUseStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleMenuUser = (event) => {
    if (event.currentTarget !== anchorElUser) {
      setAnchorElUser(event.currentTarget);
    }
  };

  function handleUserProfileCard() {
    setAnchorElUser(null);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [organization_data] = useState(
    JSON.parse(localStorage.getItem("organization_data"))
  );
  return (
    // <div className={classes.grow}>
    <AppBar
      position="fixed"
      color="inherit"
      style={{
        zIndex: 999,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        width: "100%",
        height: "65px",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12}>
          <Toolbar>
            <img
              style={{
                width: "170px",
                height: "50px",
                objectFit: "contain",
                marginLeft: "20px",
                // border:"solid",
                // position:"relative",
                // left:-20
              }}
              src={MagPieLogo}
              alt="logo"
            />
            <div className={classes.grow} />
            <div
              className={classes.sectionDesktop}
              data-tut="reactour__profile_card"
            >
              {true && (
                <>
                  <div
                    className={classes.userImage}
                    onClick={handleMenuUser}
                    data-tut="reactour__profile_user"
                  >
                    <Avatar
                      size="small"
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: theme.palette.background.contentBG,
                        color: "#fff",
                      }}
                      // src={
                      //   login?.userProfile && login?.userProfile?.url
                      //     ? login?.userProfile?.url
                      //     : `https://avatars.dicebear.com/api/initials/${name}.svg?bold=1`
                      // }
                    >
                      ss
                    </Avatar>
                    <UserProfileCard
                      anchorEl={anchorElUser}
                      onClose={handleUserProfileCard}
                      userName={name}
                      userEmail={email}
                      token={token}
                      logOut={logOut}
                      userProfile={login?.userProfile}
                      loginDetails={login}
                    />
                  </div>
                  {/* <div className={classes.border}>
                      <div
                        className={classes.name}
                        onClick={handleMenu}
                        data-tut="reactour__profile"
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ marginRight: "20px" }}
                        >
                          {organization_data?.name && organization_data?.name}
                        </Typography>
                        <Avatar
                          size="small"
                          className={classes.small}
                          src={
                            organization_data?.logo
                              ? organization_data?.logo?.url
                              : "https://imgrows.s3.us-east-2.amazonaws.com/logo/61dbef48ea21d3292041bee0_1677070761831.png"
                          }
                        ></Avatar>
                      </div>
                      {open && (
                        <ProfileCard
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          userName={userName}
                          userEmail={userEmail}
                          organisationDetails={organisation_details}
                          parent_organisation={parent_organisation}
                          viewAssessments={viewAssessments}
                          surveyListing={surveyListing}
                          token={token}
                          logOut={logOut}
                          viewAssignedAssessments={viewAssignedAssessments}
                          setCurrentOrganzation={setCurrentOrganzation}
                          loginDetails={loginDetails}
                          setCurrentOrganisationUserType={
                            setCurrentOrganisationUserType
                          }
                          setIsNewCompany={setIsNewCompany}
                          setSelectedOraganization={setSelectedOraganization}
                          setSignupProcessNo={setSignupProcessNo}
                        />
                      )}
                    </div> */}
                </>
              )}
            </div>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
    // </div>
  );
}
export default React.memo(CustomAppBar);
