import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.width,
    flexShrink: 0,
    // border:"solid red",
    // zIndex: 9,
  },
  drawerPaper: {
    width: (props) => props.width,
    display: "flex",
    justifyContent: "flex-start",
    border: `1px solid ${theme.palette.primary.light}`,
  },
  borderPrimary: {
    borderBottom:
      theme.palette.type !== "dark"
        ? `1px solid ${theme.palette.primary.light}`
        : `1px solid ${theme.palette.grey[100]}`,
  },
  typoColorPrimary: {
    color: theme.palette.primary.main,
  },
  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },

  // necessary for content to be below app bar
}));

export const TopicHeader = ({ topic, score }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.borderPrimary}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "5px 15px 5px 8px",
        // borderBottom: "2px solid #F6F6F6",
        // marginTop: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={getColoredLogo(topic)} alt="leaf" />
        <Typography
          className={classes.typoColorSecondary}
          style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Lato" }}
        >
          {topic}
        </Typography>
      </div>
      <div>
        <Typography
          className={classes.typoColorSecondary}
          style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Lato" }}
        >
          {score}
        </Typography>
      </div>
    </div>
  );
};
export const TopicData = ({ topic, score }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "12px",
        padding:"10px 15px"
        // border:"solid",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          className={classes.typoColorPrimary}
          style={{
            marginLeft: "5px",
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Lato",
          }}
        >
          {topic}
        </Typography>
      </div>
      <div>
        <Typography
          className={classes.typoColorPrimary}
          style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Lato" }}
        >
          {score}
        </Typography>
      </div>
    </div>
  );
};

export const data = [
  {
    topic: "Environment",
    score: 45,
    subTopic: [
      { topic: "Pollution Control", score: 16 },
      { topic: "Water Management", score: 14 },
      { topic: "Biodiversity", score: 13 },
      { topic: "Climate Change", score: 2 },
    ],
  },
  {
    topic: "Social Topics",
    score: 55,
    subTopic: [
      { topic: "Diversity and Inclusion", score: 14 },
      { topic: "Human Rights", score: 22 },
      { topic: "Labor Rights", score: 9 },
      { topic: "Community Development", score: 10 },
    ],
  },
  {
    topic: "Governance",
    score: 30,
    subTopic: [
      { topic: "Board Independence", score: 4 },
      { topic: "Executive Compensation", score: 10 },
      { topic: "Shareholder Rights", score: 12 },
      { topic: "Risk Management", score: 4 },
    ],
  },
  {
    topic: "Business",
    score: 50,
    subTopic: [
      { topic: "Product Quality and Safety", score: 13 },
      { topic: "Customer Privacy and Data Protection", score: 23 },
      { topic: "Supply Chain Management", score: 4 },
      // { topic: "Marketing and Advertising", score: 25 },
      { topic: "Innovation and Intellectual Property", score: 13 },
    ],
  },
  {
    topic: "Human Capital",
    score: 34,
    subTopic: [
      { topic: "Work-Life Balance", score: 9 },
      { topic: "Employment Training & Development", score: 10 },
      { topic: "Diversity and Inclusion", score: 8 },
      { topic: "Employee engagement", score: 7 },
    ],
  },
];
