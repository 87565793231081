import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import RouterCont from "./containers/router/routerCont";
import { lightTheme, darkTheme, glassTheme, gieomTheme } from "./theme";
import { CssBaseline } from "@material-ui/core";

function App() {
  const { hasDefaultTheme } = useSelector((state) => state.esgGptReducer);
  return (
    <>
      <ThemeProvider theme={hasDefaultTheme ? gieomTheme : lightTheme}>
        <CssBaseline />
        <RouterCont  />
      </ThemeProvider>
    </>
  );
}

export default App;
