import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import EsgGptMainLeft from "../../screen/esgGpt/EsgMainLeft";
import EsgGptDrawerTabs from "./EsgGptDrawerTabs";
import EsgMoreBUttons from "./EsgGptMoreBUtton";
import EsgGptSearchBar from "./EsgGptSearchBar";
import EsgGptAddCompanyButton from "./EsgGptAddCompanyButton";
import EsgGptCompanyList from "./EsgGptCompanyList";
import CustomAppBar from "../../UI/appBar/appBar";
import chevronsLeft from "../../../images/chevronsLeft.svg";
import EsgGpSideDrawer from "./EsgGptSideDrawer";
import {
  Paper,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import { useSelector } from "react-redux";

const useStylesLayput = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    // border:"solid red",
    width: "100vw",
    // marginLeft: "1.5vw",
    // alignItems:"center"
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,

    // [theme.breakpoints.down("1000px")]: {
    //   width: "90vw",
    //   marginLeft: "30px",
    // },
    // [`${theme.breakpoints.down(
    //   "1000"
    // )} and (orientation: landscape) and (orientation: portrait)`]: {
    //   width: "90vw",
    //   marginLeft: "30px",
    // },
    // '@media (orientation: landscape)': {
    //   flexDirection: `orientation`,
    // },
    // flexDirection:"column"
    // border:"solid red"
  },

  // necessary for content to be below app bar
  content: {
    // alignSelf:"center",
    // marginLeft:"auto"
    // flexGrow: 1,
    width: "100%",
    // backgroundColor: "red",
    padding: theme.spacing(3),
    // border:"solid",
    // marginRight: "10px",
  },
  secondaryColorMain: {
    color: theme.palette.primary.main,
    transform: "rotate(180deg)",
  },
  toolbar: theme.mixins.toolbar,
  iconBackground: {
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[100],
    cursor: "pointer",
    zIndex: 99999,
    position: "absolute",
    right: 5,
    top: 80,
  },
  hideDrawer: {
    display: "block",
    [`${theme.breakpoints.down("sm")} `]: {
      display: "none",
      // width: "95vw",
      // marginLeft: "30px",
    },
  },
}));

function EsgGptlayout({ EsgCompanyList, children }) {
  const classes = useStylesLayput();
  const theme = useTheme();

  const [isgptDrawerOpen, setIsgptDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setIsgptDrawerOpen((preValue) => !preValue);
  };

  const handleResolution = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper elevation={0} className={classes.root}>
      <CssBaseline />
      {/* {isgptDrawerOpen && (
        <EsgGpSideDrawer
          isgptDrawerOpen={isgptDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
        />
      )} */}
      {/* <div style={{border:"solid",flex:1}}> */}
      <CustomAppBar isOpen={true} />

      {/* </div> */}
      <div className={classes.toolbar} />

      {/* <Toolbar /> */}

      {/* <div className={classes.hideDrawer}> */}
        {/* <EsgGptMainLeft
          drawerTabs={<EsgGptDrawerTabs />}
          drawerCompanyList={EsgCompanyList}
          drawerSearchBar={<EsgGptSearchBar />}
          drawerAddCompanyButton={<EsgGptAddCompanyButton />}
          drawerMoreButton={<EsgMoreBUttons hasIconOnly={true} />}
          width={"280px"}
          isgptDrawerOpen={true}
          handleResolution={handleResolution}
          handleDrawerOpen={handleDrawerOpen}
        /> */}
      {/* </div> */}

      <main className={classes.content}>{children}</main>

      {!isgptDrawerOpen && false&& (
        <IconButton
          variant="outlined"
          className={classes.iconBackground}
          style={{
            // border: "1px solid #3E8DDD",
            // backgroundColor: "#FFFFFF",
            marginLeft: "10px",
            padding: "5px",
            borderRadius: "200px",
          }}
          size="small"
        >
          <DoubleArrowOutlinedIcon
            className={classes.secondaryColorMain}
            onClick={handleDrawerOpen}
          />
        </IconButton>
      )}
    </Paper>
  );
}

export default EsgGptlayout;
