import React from "react";
import RiskAssessmentCont from "../riskAssessment/riskAssessment";

function CorporateEvaluator(props) {
  return (
    <div>
     <RiskAssessmentCont isEvaluator={true} {...props} />
    </div>
  );
}

export default CorporateEvaluator;
