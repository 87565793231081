import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  addCompanyBrm,
  setCorporateAns,
} from "../../redux/actions/brm/BrmActions";
// import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
import CorporateEvaluator from "../../components/widgets/riskAssessment/CorporateEvaluator";
import { getCalculatedSurvey } from "../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { getSectorIndustries } from "../../redux/actions/brm/BrmActions";
import {
  createOrganization,
  viewDetailsOrganization,
  viewOrganization,
} from "../../redux/actions/organization/organizationAction";
import { editMember, viewMember } from "../../redux/actions/member/member";

function CeCont(props) {
  // useEffect(() => {
  //   props.getOrganizations(props.login.token, window.location.hostname);
  // }, [props.login.token]);
  return  <CorporateEvaluator {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  // getOrganizations: (payload) => {
  //   dispatch(getOrganizations(payload));
  // },
  // getMaturityMatrix: (payload) => {
  //   dispatch(getMaturityMatrix(payload));
  // },
  setCorporateAns: (payload) => {
    dispatch(setCorporateAns(payload));
  },
  addCompanyBrm: (payload) => {
    dispatch(addCompanyBrm(payload));
  },
  getCalculatedSurvey: (token, organization_id) => {
    dispatch(getCalculatedSurvey(token, organization_id));
  },
  getSectorIndustries: (token) => {
    dispatch(getSectorIndustries(token));
  },
  viewOrganization: (token, bank_id) => {
    dispatch(viewOrganization(token, bank_id));
  },
  viewDetailsOrganization: (token, organization_id) => {
    dispatch(viewDetailsOrganization(token, organization_id));
  },
  createOrganization: (
    token,
    bankId,
    name,
    isin,
  year,
    logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors
  ) => {
    dispatch(
      createOrganization(
        token,
        bankId,
        name,
        isin,
        year,
        logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors
      )
    );
  },
  viewMember: (token, organization_id) => {
    dispatch(viewMember(token, organization_id));
  },
  editMember: (token, user_id, organization_id, role, category) => {
    dispatch(editMember(token, user_id, organization_id, role, category));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CeCont);
