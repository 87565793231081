import {
  AppBar,
  Box,
  Card,
  CardContent,
  createTheme,
  Grid,
  IconButton,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BrmDataLeft from "./brmDataLeft/brmDataLeft";
import BrmDataRight from "./brmDataRight.js/brmDataRight";
import ProceedButtonCard from "../../UI/proceedButtonCard/proceedButtonCard";

export const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

function BrmDetaails(props) {
  const history = useHistory();
  // const location = useLocation();

  const useStyles = makeStyles((theme) => ({
    cardContainerDefault: {
      width: "100%",
      height: "100px",
      background: "#FDFDFD",
      border: "1px solid #C0D4E9",
      borderRadius: "8px",
      marginBottom: "16px",
    },

    mainHeadingDefault: {
      fontWeight: 500,
      fontSize: "16px",
      color: "#242424",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      marginBottom: 8,
    },

    subHeadingDefault: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#BBBBBB",
      letterSpacing: "0.0025em",
    },

    cardContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      // cursor: "pointer",
    },
    cardContainer: {
      backgroundColor: "#FFFFFF",
      // marginBottom: '15px',
      borderRadius: 5,
      height: "100%",
    },

    cardTypography: {
      fontWeight: "600",
    },
    AppBar: { boxShadow: "none" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
  }));
  const { brmData, setBrmMainTabNo, setBrmSubTabNo } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [riskTabNo, setRiskTabNo] = useState(0);
  useEffect(() => {
    setBrmMainTabNo(0);
    setBrmSubTabNo(0);
    function handleWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWidth);

    return () => {
      // cancel the subscription
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  useEffect(() => {
    // console.log(props?.login?.token,location?.state?.organization)
    if (brmData.brmSubTabNo === 2 && riskTabNo === 0) {
      // props.getCalculatedSurvey(
      //   props?.login?.token,
      //   brmData?.organizationDetails[0]?._id
      //     ? brmData?.organizationDetails[0]?._id
      //     : localStorage.getItem("organization_id")
      // );
    } else if (brmData.brmSubTabNo === 2 && riskTabNo === 1) {
      // props.getCalculatedSurvey(
      //   props?.login?.token,
      //   brmData?.organizationDetails[0]?._id
      //     ? brmData?.organizationDetails[0]?._id
      //     : localStorage.getItem("organization_id")
      // );
      // props.getNewArticles();
      // props.getBarChartData();
      // props.getTopicWiseData();
    }
  }, [brmData.brmSubTabNo, riskTabNo]);

  const classes = useStyles();
  // const [mainTabNo, setMainTabNo] = useState(0);
  // onClick={() => history.push("/risk_analysis")}

  return (
    <div>
      <Grid container>
        <ArrowBack
          onClick={() => {
            history.goBack();
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          {Array.isArray(brmData.organizationDetails) &&
            brmData.organizationDetails[0].name}
        </Typography>
      </Grid>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          {props.isEvaluator && (
            <Tabs
              value={brmData.brmMainTabNo}
              onChange={(e, newVal) => {
                setBrmMainTabNo(newVal);
                setBrmSubTabNo(0);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Risk Analysis</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                onClick={() => {
                  // viewAllUsers(login.token, login.current_organisation, '');
                  // viewAssignedMembers(login.token, assessment_id, 1);
                  // getInternalMaturityMatrix(login.token, assessment_id);
                  // viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
                }}
                label={
                  <span className={classes.tabLabel}>Public Information</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                onClick={() => {
                  // viewAllUsers(login.token, login.current_organisation, '');
                  // viewAssignedMembers(login.token, assessment_id, 1);
                  // getInternalMaturityMatrix(login.token, assessment_id);
                  // viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
                }}
                label={<span className={classes.tabLabel}>Controvercies</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                onClick={() => {
                  // viewAllUsers(login.token, login.current_organisation, '');
                  // viewAssignedMembers(login.token, assessment_id, 1);
                  // getInternalMaturityMatrix(login.token, assessment_id);
                  // viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
                }}
                label={<span className={classes.tabLabel}>ESG Profile</span>}
              />
              {/* <Tab
                disableRipple
                className={classes.tab}
                onClick={() => {
                  // viewAllUsers(login.token, login.current_organisation, '');
                  // viewAssignedMembers(login.token, assessment_id, 1);
                  // getInternalMaturityMatrix(login.token, assessment_id);
                  // viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
                }}
                label={<span className={classes.tabLabel}>Charts</span>}
              /> */}
            </Tabs>
          )}
          {!props.isEvaluator && (
            <Tabs
              value={brmData.brmMainTabNo}
              onChange={(e, newVal) => {
                setBrmMainTabNo(newVal);
                setBrmSubTabNo(0);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Self Assessment</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Survey</span>}
              />
            </Tabs>
          )}
        </AppBar>
      </MuiThemeProvider>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          // height: windowWidth >= 992 ? "76vh" : "91%",
          padding: 10,
        }}
      >
        <Grid item xs={12} sm={11} md={4} style={{ height: "75vh" }}>
          <BrmDataLeft
            // mainTabNo={mainTabNo}
            // setMainTabNo={setMainTabNo}
            {...props}
          />
        </Grid>
        <Grid item xs={12} sm={11} md={8} style={{ height: "75vh" }}>
          <BrmDataRight
            riskTabNo={riskTabNo}
            setRiskTabNo={setRiskTabNo}
            // organizaitonDetails={brmData.organizationDetails}
            // organizaitonId={brmData.organizaitonDetails[0]._id}
            {...props}
          />
        </Grid>
        <Grid item xs={11} md={11}>
          {<ProceedButtonCard {...props} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default BrmDetaails;
