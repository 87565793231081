import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_ASK_MESSAGE,
  SET_CHANGE_THEME
} from "../../../constants/esgGptConstant";

export const setAcknowledged = (payload) => {
  return {
    type: SET_ACKNOWLEDGED,
    payload,
  };
};

export const setOrganization = (payload) => {
  return {
    type: SET_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyDetails = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyLogo = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION_LOGO,
    payload,
  };
};

export const setAskMessage = (payload) => {
  return {
    type: SET_ASK_MESSAGE,
    payload,
  };
};

export const setChangeTheme = (payload) => {
  return {
    type: SET_CHANGE_THEME,
    payload,
  };
};

export const createOrganizationEsgGpt = (
  token,
  name,
  isin,
  sector,
  industry,
  industry_code,
  logo
) => {
  return (dispatch) => {
    dispatch(set_loader);
    var formData = new FormData();
    const data = encrypt({
      "user-token": token,
      name,
      isin,
      sector,
      industry,
      industry_code,
      // logo,
    });

    formData.append("data", data);
    formData.append("logo", logo);

    return fetch(
      UNIVERSAL.BASEURL + "/organization/create_organization_esg_gpt",
      {
        method: "POST",
       
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson?.result);
          dispatch(setAcknowledged(responseJson?.status));
          dispatch(setNewCompanyDetails(responseJson?.result));
        }

        dispatch(unset_loader);
      });
  };
};

export const viewOrganizationEsgGpt = (token) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/view_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganization(responseJson?.result));
          // dispatch(setNewCompanyDetails(responseJson?.result[0]))
        }

        dispatch(unset_loader);

        dispatch(unset_loader);
      });
  };
};
