import { makeStyles } from "@material-ui/core";

const proceedButtonUseStyles = makeStyles((theme) => ({
    checkmargin: {
      marginLeft: 20,
      marginTop: 0,
      [theme.breakpoints.down('xs')]: {
        marginTop: 10
      }
    },
    card:{
        textAlign: 'right',
        position: 'fixed',
        bottom: 0,
        width: '94%',
        zIndex: 100,
        height: 50
      },
      costumButton:{ marginLeft: 20 },
      link:{ textDecoration: 'none' },
      cardContent:{ padding: 7 },
  }));
  
  export default proceedButtonUseStyles;
