import React from "react";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const greenTheme = createTheme({
  palette: {
    primary: { main: "#3374B9" },
    secondary: { main: "#47BB76" },
    action:{
      disabledBackground:'#C0D4E9',
    }
  },
  overrides:{
    MuiButton:{
      containedSecondary: {
        color: "#ffffff"
      },
      containedPrimary: {
        color: "#ffffff"
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "#ffffff"
        }
      },
      textSecondary: {
        "&:hover": {
          backgroundColor: "#ffffff"
        }
      }
    }
  }
})
export default class CustomButton extends React.PureComponent {

  render() {
    return (
      <>
        <MuiThemeProvider theme={greenTheme}>
          <Button   {...this.props} />
        </MuiThemeProvider>
      </>
    )
  }
}

