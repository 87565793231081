import React from "react";

import { Paper } from "@material-ui/core";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptChatField from "../EsgGptChatField";

import { ModifiedList } from "../esgGptAddNewCompany/EsgGptUpdatedList";
import analytics from "../../../../images/Analytics.png";
import { ChatPlayGround } from "../esgGptCompanyProfile/EsgGptCompanyRight";

function EsgGptChat() {
  const [chatInitiate, setChatInitiate] = React.useState(false);
  const handleChat = () => {
    setChatInitiate(true);
  };
  
  return (
    <EsgGptlayout EsgCompanyList={<ModifiedList />}>
      <Paper
        style={{
          height: "85vh",
          width: "100%",
          overflow: "scroll",
          marginBottom: "1.5%",
          position: "relative",
          
        }}
        elevation={0}
      >
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            // height:'100%',

          }}
          onClick={handleChat}
        >
          <ChatPlayGround />
        </div>
      </Paper>

      {/* <EsgGptChatField /> */}
    </EsgGptlayout>
  );
}

export default EsgGptChat;
