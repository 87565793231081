import {
  Grid,
  LinearProgress,
  makeStyles,
  Card,
  CardContent,
  withStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import score0Large from "../../../images/score0Large.jpg";
import beginerLarge from "../../../images/beginerLarge.png";
import intermediateLarge from "../../../images/intermideateLarge.png";
import expertLarge from "../../../images/expertLarge.png";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import clsx from "clsx";
import chatToolTip from "../../../images/chatToolTip.png";
import progressIndicator from "../../../images/progressIndicator.png";
import { Radar } from "react-chartjs-2";
import RadarChart from "../../UI/radarChart/radarChart";
function RiskAnalysisRightTab(props) {
  const { brmData, riskEvaluator, login, organizaitonId } = props;
  const useStyles = makeStyles(() => ({
    topicStyle: {
      width: "40px",
      height: "40px",
      marginTop: "5px",
      position: "relative",
      top: "5px",
    },
    mainGrid: {
      textAlign: "center",
    },
    scoreImage: {
      width: "256px",
      height: "246px",
      marginTop: "10px",
    },
    maxScoreTypo: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
    },
    scoreTypo: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "xx-large",
    },
    sharesurvey: {
      color: "green",
      fontWeight: 600,
    },
    describe: {
      color: "grey",
      fontSize: "x-small",
      marginTop: "11px",
    },
    Table: {
      outline: "0.5px solid lightGrey",
      borderRadius: "5px",
      boxShadow: "1px 1px 1px",
      height: "100%",
    },
    TableContainer: {
      padding: "3px",
    },
    TableCell: {
      padding: "5px",
      border: "none",
    },
    hedaderCell: { color: "grey", fontSize: "x-small" },
    topicCell: { padding: "4px 4px" },
    lableCell: {
      // width: '193px',
      // height: '20px',
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      // lineHeight: "20px",
    },
    scoreCell: {
      textAlign: "right",
      // width: '32px',
      // height: '28px',
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "28px",
    },
    cardContainer: {
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
      // height:'100%'
    },
    LinearProgress: {
      // color:'success.main',
      margin: "-25px 10px 25px 10px",
    },
    progressIndicator1: {
      position: "relative",
      left: "-18%",
      top: "12px",
      backgroundColor: "#247243",
      zIndex: 1,
    },
    progressIndicator2: {
      position: "relative",
      left: "17.7%",
      top: "12px",
      backgroundColor: "#247243",
      zIndex: 1,
    },
  }));
  const Progress = withStyles({
    root: {
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "green",
      },
      height: "7px",
      borderRadius: "8px",
    },
  })(LinearProgress);
  const classes = useStyles();
  const [scoreList, setScoreList] = useState([
    {
      component: <Environmental className={classes.topicStyle} />,
      lable: "Community",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Community"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Community"
            ]
          : 0,
    },
    {
      component: <Social className={classes.topicStyle} />,
      lable: "Human Rights",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Human rights"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Human rights"
            ]
          : 0,
    },
    {
      component: <Business className={classes.topicStyle} />,
      lable: "Management",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Management"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Management"
            ]
          : 0,
    },
    {
      component: <Governance className={classes.topicStyle} />,
      lable: "Product Responsiblity ",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories[
          "Product responsibility"
        ]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Product responsibility"
            ]
          : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Resource Use ",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Resource use"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Resource use"
            ]
          : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Stakeholder ",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Shareholders"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Shareholders"
            ]
          : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Workforce ",
      score:
        riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        riskEvaluator?.calculatedEsgDisclosure?.final_categories["Workforce"]
          ? riskEvaluator?.calculatedEsgDisclosure?.final_categories[
              "Workforce"
            ]
          : 0,
    },
  ]);
  const [questions, setQuestions] = useState([
    {
      order: 1,
      category: "Product responsibility",
      question:
        "How concerned are you about controversies linked to responsible R&D in our company?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 2,
      category: "Resource use",
      question:
        "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 3,
      category: "Workforce",
      question:
        "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
      answers: [
        ["Strongly disagree", 1],
        ["Disagree", 2],
        ["Neutral", 3],
        ["Agree", 4],
        ["Strongly agree", 5],
      ],
    },
    {
      order: 4,
      category: "Workforce",
      question:
        "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 5,
      category: "Human rights",
      question:
        "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
      answers: [
        ["Not concerned at all", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 6,
      category: "Management",
      question:
        "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 7,
      category: "Workforce",
      question:
        "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 8,
      category: "Workforce",
      question:
        "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
      answers: [
        ["Never", 5],
        ["Rarely", 4],
        ["Sometimes", 3],
        ["Often", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 9,
      category: "Community",
      question:
        '"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing."',
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 10,
      category: "Community",
      question:
        "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Greatly concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 11,
      category: "Shareholders",
      question:
        "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 12,
      category: "Community",
      question:
        'How much do you agree with the following statement: "There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.',
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 13,
      category: "Community",
      question:
        "How often do you come across controversies related to patents and intellectual property infringements?",
      answers: [
        ["Rarely", 5],
        ["Occasionally", 4],
        ["Sometimes", 3],
        ["Frequently", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 14,
      category: "Community",
      question:
        "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 15,
      category: "Shareholders",
      question:
        "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
      answers: [
        ["Not concerned at all", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 16,
      category: "Shareholders",
      question:
        "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 17,
      category: "Product responsibility",
      question:
        "How often do you believe that access to a product has been associated with controversies?",
      answers: [
        ["Never", 5],
        ["Rarely", 4],
        ["Sometimes", 3],
        ["Often", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 18,
      category: "Product responsibility",
      question:
        "How many controversies have been linked to employees or customers in the past year?",
      answers: [
        ["None", 5],
        ["A few", 4],
        ["Some", 3],
        ["Many", 2],
        ["A lot", 1],
      ],
    },
    {
      order: 19,
      category: "Product responsibility",
      question:
        "How concerned are you about the number of controversies linked to customer health and safety at this company?",
      answers: [
        ["Not concerned at all", 5],
        ["Mildly concerned", 4],
        ["Somewhat concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 20,
      category: "Community",
      question:
        'How strongly do you agree with the statement: "The company should prioritize avoiding controversies in countries that do not respect human rights principles"?',
      answers: [
        ["Strongly agree", 5],
        ["Agree", 4],
        ["Neutral", 3],
        ["Disagree", 2],
        ["Strongly disagree", 1],
      ],
    },
    {
      order: 21,
      category: "Human rights",
      question:
        "How concerned are you about the number of controversies linked to human rights issues within the company?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Somewhat concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 22,
      category: "Product responsibility",
      question:
        "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 23,
      category: "Product responsibility",
      question:
        "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
  ]);
  const [collect, setCollect] = useState(
    props?.riskEvaluator?.calculatedEsgDisclosure
      ? props.riskEvaluator?.calculatedEsgDisclosure?.questions
      : {}
  );
  const [chartEsgData, setChartEsgData] = useState([]);
  useEffect(() => {
    props.getCalculatedSurvey(
      props?.login?.token,
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
  
    const charting = () => {
      let cat1 = { p: [], r: [], m: [], c: [], h: [], w: [], s: [] }

      if (collect && Object.keys(collect).length > 0 && questions.length > 0) {
        let qNumb = 0;
        for (let key in collect) {
          qNumb++;
          if (questions[qNumb - 1].category === "Product responsibility") {
            cat1.p.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Resource use") {
            cat1.r.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Management") {
            cat1.m.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Community") {
            cat1.c.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Shareholders") {
            cat1.s.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Workforce") {
            cat1.w.push(collect[key]);
          }
          if (questions[qNumb - 1].category === "Human rights") {
            cat1.h.push(collect[key]);
          }
        }

        let chartData = [
          cat1.c.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            30 /
            1,
          cat1.h.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            10 /
            1,
          cat1.m.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            5 /
            1,
          cat1.p.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            30 /
            1,
          cat1.r.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            5 /
            1,
          cat1.s.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            15 /
            1,
          cat1.w.reduce((total, next) => {
            return Number(total) + Number(next);
          }, 0) /
            20 /
            1,
        ];

        setChartEsgData(chartData);
      }
    };
    charting();
  }, []);
  
  const [score, setScore] = useState(
    riskEvaluator?.calculatedEsgDisclosure?.final_score
      ? riskEvaluator?.calculatedEsgDisclosure?.final_score
      : 0
  );

  return (
    <Grid>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={100}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        <CardContent
          style={{ padding: "10px 5px" }}
          className={classes.cardContent}
        >
          <Grid container style={{ padding: "5px" }} component={Paper}>
            <Grid
              item
              md={5}
              sm={5}
              lg={5}
              xs={12}
              className={classes.mainGrid}
            >
              <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                Score
              </Typography>
              {score === 0 && (
                <img
                  src={score0Large}
                  className={classes.scoreImage}
                  alt="score0Large"
                />
              )}
              {score > 0 && score < 33.3 && (
                <img
                  src={beginerLarge}
                  className={classes.scoreImage}
                  alt="beginerLarge"
                />
              )}
              {score >= 33.3 && score < 66.6 && (
                <img
                  src={intermediateLarge}
                  className={classes.scoreImage}
                  alt="intermediateLarge"
                />
              )}
              {score >= 66.6 && (
                <img
                  src={expertLarge}
                  className={classes.scoreImage}
                  alt="expertLarge"
                />
              )}
              <Typography className={classes.maxScoreTypo}>
                <span className={classes.scoreTypo}>{score}</span> / 100{" "}
              </Typography>
              {score === 0 && (
                <Typography className={classes.sharesurvey}>
                  Share Survey To identify your Score.
                </Typography>
              )}
              {score > 0 && score < 33.3 && (
                <Typography className={classes.sharesurvey}>
                  Low, Good progress.
                </Typography>
              )}
              {score >= 33.3 && score < 66.6 && (
                <Typography className={classes.sharesurvey}>
                  Medium, Good progress.
                </Typography>
              )}
              {score >= 66.6 && (
                <Typography className={classes.sharesurvey}>
                  High, Great !
                </Typography>
              )}
              {/* <Typography className={classes.discribe}>
                Your Survey Data is Generated using the contributions from your
                stakeholders
              </Typography> */}
              <Grid md={5} lg={5} sm={12}>
                <div
                  style={{
                    height: 46,
                    width: 112,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    left: `${
                      score <= 6
                        ? 0
                        : score <= 30
                        ? score - 3.5
                        : score <= 60
                        ? score - 4
                        : score <= 70
                        ? score - 5.2
                        : score <= 90
                        ? score - 5.8
                        : score <= 95
                        ? score - 10
                        : 85
                    }%`,
                    marginTop: 10,
                  }}
                >
                  <img
                    // style={{ height: "85%", width: "85%" }}
                    src={progressIndicator}
                    alt=""
                  />
                </div>
                <div className={classes.LinearProgress}>
                  <span className={classes.progressIndicator1}>'</span>
                  <span className={classes.progressIndicator2}>'</span>
                  <Progress
                    // style={{ zIndex: 0 + '!important',}}
                    color="primary"
                    variant="determinate"
                    value={score}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item md={7} sm={7} lg={7} xs={12}>
              <Typography style={{ fontSize: "14px" }}>
                Bifurcated Score
              </Typography>
              <TableContainer className={classes.TableContainer}>
                <Table className={classes.Table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={clsx(classes.TableCell, classes.hedaderCell)}
                      >
                        Topic
                      </TableCell>
                      <TableCell
                        className={clsx(classes.TableCell, classes.hedaderCell)}
                      >
                        Lable
                      </TableCell>
                      <TableCell
                        className={clsx(classes.TableCell, classes.hedaderCell)}
                      >
                        Score
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {scoreList &&
                    scoreList.map((topic) => {
                      return (
                        <TableBody key={topic.lable}>
                          <TableCell
                            className={clsx(
                              classes.TableCell,
                              classes.topicCell
                            )}
                          >
                            {topic.component}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.TableCell,
                              classes.lableCell
                            )}
                          >
                            {topic.lable}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.TableCell,
                              classes.scoreCell
                            )}
                          >
                            {topic.score}
                          </TableCell>
                        </TableBody>
                      );
                    })}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={100}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        <CardContent className={classes.cardContent}>
          <RadarChart chartEsgData={chartEsgData} {...props} />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default RiskAnalysisRightTab;
