import React, { useEffect, useState } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import PublicReports from "./publicReports";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Icon,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function Documents({
  showPeerDocs,
  companyName,
  setShowpeerDocs,
  brmData,
  ...props
}) {
  const docStyles = makeStyles((theme) => ({
    grid2: { marginTop: 15 },
    grid3: { display: "flex", justifyContent: "flex-end" },
    grid4: { paddingLeft: 75 },
    grid5: { paddingRight: 75 },
    grid6: { paddingRight: 10 },
    grid7: { marginTop: 20 },

    AppBar: { boxShadow: "none", maxHeight: "25px" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
    apiCont: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: "1px solid lightgrey",
      borderRadius: "8px",
    },
  }));
  const classes = docStyles();

  const [reportsTab, setReportsTab] = useState(0);
  const [file, setFile] = React.useState([]);

  const handleFile = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined && setFile([...file, ...uploadFile]);
  };

  //   useEffect(()=>{
  //     const copyfile = new Blob([file], { type: "application/pdf" });
  //     //Build a URL from the file
  //     const fileURL = URL.createObjectURL(copyfile);
  //     //Open the URL on new Window
  //     //  const pdfWindow = window.open();
  //     //  pdfWindow.location.href = fileURL;
  //     console.log('s',fileURL)
  //   },[file])

  const handleFileRemove = (value) => {
    setFile(value);
  };

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showPeerDocs && brmData?.brmSubTabNo === 2 && (
            <Icon>
              <ArrowBackIcon
                onClick={() => setShowpeerDocs(false)}
                style={{ color: "#3374B9", cursor: "pointer" }}
              />
            </Icon>
          )}
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              marginLeft: showPeerDocs ? "5px" : 0,
            }}
          >
            {showPeerDocs && brmData?.brmSubTabNo === 2
              ? companyName
              : "Public Reports Upload"}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "20%",
          }}
        >
          <Typography
            style={{
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
           cursor: "pointer"
            }}
          >
            Filter
          </Typography>

          <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            accept=".pdf"
            onChange={(e) => handleFile(e)}
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="contained-button-activityFile">
            <Typography
              style={{
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "5px",
                fontSize: "14px",
              }}
            >
              Upload file
            </Typography>
          </label>
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={reportsTab}
            onChange={(e, newVal) => {
              setReportsTab(newVal);
              // setReportsTab(0);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Reports</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Data Breakdown</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={reportsTab} index={0}>
        <PublicReports
          {...props}
          file={file}
          handleFileRemove={handleFileRemove}
        />
      </TabPanel>
      <TabPanel value={reportsTab} index={1}>
        <DataBreakdown />
      </TabPanel>
    </div>
  );
}

export default Documents;
