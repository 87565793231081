import UNIVERSAL from "../../../config";
import {
  SET_COMPANY_DATA,
  SET_ELASTIC_ORGANIZATIONS,
  SET_TOTAL_COMPANIES,
  SET_CONTROVERSY_LINKS,
} from "../../../constants/riskDashboardConstants";
import { set_loader, unset_loader } from "../loader/loader_action";
import { getControversyData } from "../riskEvaluator/riskEvaluatorActions";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

export const setElasticOrganizations = (payload) => {
  return {
    type: SET_ELASTIC_ORGANIZATIONS,
    payload,
  };
};

export const setTotalCompanies = (payload) => {
  return {
    type: SET_TOTAL_COMPANIES,
    payload,
  };
};

export const setCompanyData = (payload) => {
  return {
    type: SET_COMPANY_DATA,
    payload,
  };
};

export const setControversyLinks = (payload) => {
  return {
    type: SET_CONTROVERSY_LINKS,
    payload,
  };
};

export function getElasticOrganizations(token, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      limit,
      starting_after,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/get_list_of_organizations",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setElasticOrganizations(responseJson.result));
          dispatch(setTotalCompanies(responseJson.total));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCompanyData(token, company_id) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCompanyData(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateControversyType(token, id, controversy_type, isin) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      id,
      controversy_type,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_company_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          id && dispatch(getControversyData(token, isin));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function publishLinks(token, ids, isin) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      ids,
    });

 
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_company_publish_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getControversyLinks(token, isin));
          dispatch(getControversyData(token, isin));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateCompanyData(token, company_id, answers, setAdminTabNo) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      company_id,
      answers,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_organization_data",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getCompanyData(token, company_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          setAdminTabNo(1);
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getControversyLinks(token, isin) {
  return (dispatch) => {
    // "eyJhbGciOiJIUzI1NiJ9.dGhpa3NobmFAYXBwc3RvbmUuaW4._gdKET5wDie_Abuts4XEDlfm0idpeInVg2-JvPCLQ3w"
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin,
    });
    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setControversyLinks(responseJson.result.result));
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

//   const setOrganizationsList = (payload) => {
//     return {
//       type: SET_ORGANIZATIONS_LIST,
//       payload,
//     };
//   };
